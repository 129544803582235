import React from 'react';
import "./menu-overlay.scss"
import menuicon from "../../assets/svg/baseline-menu-24px.svg"
import {Trail,  Transition, animated, config  } from 'react-spring/renderprops';
import { NavLink } from "react-router-dom";
import menuData from "../../data/menu";

import { Elastic} from "gsap";


export default class MenuIcon extends React.PureComponent {
	state = {
		items:menuData
	};

	//inAnimation =

	toggleMenu = () => {
		/*this.setState({items:[]}, () => {
			this.props.toggleMenu()
		})*/
		this.props.toggleMenu()
	};


	leave = () => {
		console.log (" MenuOverlay.js >  = "  );
		return
	}

	render(){


		//const subItems = this.props.menuOpen ? this.state.items : []
		//const to = this.props.menuOpen ? {opacity:1, transform:'translate3d(40px,100px,0)'}	: {opacity:0, transform:'translate3d(350px,100px,0)'};
		const to = this.props.menuOpen ? {opacity:1, transform:'translate3d(0px,0px,0)'}	: {opacity:0, transform:'translate3d(0px,50px,0)'};

		return (
				<Transition
					native
					items={this.props.menuOpen }
					from={{ opacity: 0 }}
					enter={{ opacity: 1 }}
					leave={{ opacity: 0, config:{delay:200} }}
					config={{ ...config.default }}>
					{item => props => {

						if (item) {
							return (

								<animated.div style={props} className="app-menu-overlay ">

										<div className="h-100 d-flex " >

												<div className="w-100 menu-items d-flex flex-column justify-content-center justify-content-lg-start " >
													<Trail
														native
														items={this.state.items}
														keys={item => item.title}
														//from={{ opacity:0, transform: 'translate3d(150px,100px,0)' }}
														from={{ opacity:0, transform: 'translate3d(0px,100px,0)' }}
														to={ to }

														config={{ tension: 650, friction: 50, delay:200}}

														>

														{item => props =>{
															console.log (" MenuOverlay.js > props.k = " , props.k);

															return (<animated.div style={props} className="menu-item align-items-start">
																<NavLink exact onClick={this.toggleMenu} to={item.to}>
																	<h1>
																		<span className="lineLeft"></span>
																		{item.title}
																		<span className="lineRight"></span>
																	</h1>
																</NavLink>
															</animated.div>)
														}
														}
													</Trail >

											</div>
										</div>
								</animated.div>
							)
						} else {
							return null
						}
					}}
				</Transition>











		)
	}
}
