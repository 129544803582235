/**
 * Created by @author @ddennis - ddennis.dk aka fantastisk.dk/works aka meresukker.dk on 14-11-2018.
 */
import React, { Component } from 'react';
import TopNavigation from "../menu/TopNavigation";


export default class Marketplace extends Component {


	render(){

		return (


				<div className="generic-page h-100 w-100 " >

					<TopNavigation/>

					<div className="offwhite h-100 w-100 "  >

						<div className="row h-100" style={{}}>

							<div className="col-12  d-flex">

								<div className="w-100 text-center mt-3 page-headline d-flex flex-column justify-content-center" >
									<h1>Marketplace will ultimo 2019</h1>

									<p className="text-center">
										Stay tuned !
									</p>

								</div>

							</div>
						</div>

					</div>
				</div>
		)
	}

}
