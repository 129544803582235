/**
 * Created by @author @ddennis - ddennis.dk aka fantastisk.dk/works aka meresukker.dk on 05-02-2019.
 */
import React, { Component } from 'react';
import Waypoint from "react-waypoint";
import TweenMax from 'gsap'

export default class Reveal extends Component {

	state = {isEntered:false}
	visible = false

	waypointEnter = (o) => {
		console.log(" reveal.js > o = ", o);
		//this.setState({isEntered:true})

		if(o.currentPosition === "inside" && this.visible === false){
			const startYpos = o.previousPosition == "above" ? -100 : 200;
			TweenMax.set(this.myElement , { y:startYpos });
			this.visible = true
			TweenMax.to(this.myElement, .7, {alpha:1, y:0, delay:.1, ease:"Expo.easeOut"});
		}

		if(o.currentPosition !== "inside" ){
			this.visible = false
		}


	};


	waypointLeave = (o) => {
		console.log (" Reveal.js > hide = " ,o);

		if(o.currentPosition === "inside" ){
			return
		}

		this.visible = false
		TweenMax.to(this.myElement,.5, {alpha:0,  ease:"Expo.easeOut"});
	};

	componentDidMount(){
		TweenMax.set(this.myElement , {alpha:0, y:200});

	}

	render(){

		const { children } = this.props;

		return (
		   <Waypoint bottomOffset='10%' onEnter={this.waypointEnter} onLeave={this.waypointLeave}>
			   <div ref={(thisDiv) => {this.myElement = thisDiv}}>
				   {children( {"isEntered":this.state.isEntered} )}
			   </div>

		   </Waypoint>
		)
	}

}


