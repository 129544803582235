/**
 * Created by @author @ddennis - ddennis.dk aka fantastisk.dk/works aka meresukker.dk on 14-11-2018.
 */
import React, { Component } from 'react';
import TopNavigation from "../menu/TopNavigation";
import SideMenu from "../menu/SideMenu";
import './influencers.scss';
import backgroundImage from '../../assets/brands/header-image.jpg'

export default class Influencers extends Component {



	render(){

		return (

				<div className="generic-page influencers-top h-100 w-100 " >

					<TopNavigation/>

					<div className="offwhite w-100 bg-image" style={{height:"inherit"}}>

						<div className="row h-100">

							<SideMenu > </SideMenu>

							<div className="col-12 col-md-11  d-flex flex-column justify-content-center ">

								<div className="headline align-self-center" >

									<div className="text-center" style={{maxWidth:600}}>
										<h1 style={{color:"white"}}>You bring the passion.<span className="d-inline-block">We’ll bring the brands!</span>
									</h1>
										<p className="pr-0 pr-md-4">
											Join our network today and start working with international brands. Take the
											firstmover-test, create a profile, add a bio and you’re ready to apply for
											campaigns and get paid for the posts you share! What are you waiting for?
										</p></div>
								</div>

							</div>

						</div>

					</div>
				</div>



		)
	}

}
