/**
 * Created by @author @ddennis - ddennis.dk aka fantastisk.dk/works aka meresukker.dk on 08-01-2019.
 */
import * as PIXI from 'pixi.js'
import { PixiBox } from "./ddennis/PixiBox";
import { getTxt } from './Txt'
import { PixiRoundRect } from "./ddennis/PixiRoundRect";
import { PixiCircle } from "./ddennis/PixiCircle";
import TweenMax from 'gsap/TweenMax'
import { PixiTriangle } from "./ddennis/PixiTriangle";

export class Card extends PIXI.Container {

	constructor(item, _w, _h, padding = -15){
		super();

		let color = "0x0027A4";

		this.item = item;
		this.padding = padding;
		let hhh = 0;

		this.boxRadius = 6;
		let txt = null;
		let nameTxt = null;

		if (item.type === "box"){


			const circleRadius = 20;
			this.circle = new PixiCircle(20);
			this.circle.x = (circleRadius * .5);
			this.addChild(this.circle);
			const boxHeight = (_h - padding) - (circleRadius + 100);

			txt = getTxt(item.txt, 14, "white", false, null, true, (_w - padding)-15 );
			txt.x = 14;
			txt.y = this.circle.height // + (this.background.height * .5 - txt.height * .5);
			this.addChild(txt);


			this.background   = new PixiRoundRect(_w - padding, txt.height +30, color, 1, 0);
			this.background.y = circleRadius + 5

			const triangle = new PixiTriangle(15, 15, color );
			triangle.rotation = 1.00;
			triangle.x = 30 ;
			triangle.y = (this.background.y - triangle.height * .5);
			this.addChildAt(triangle, 0);

			nameTxt = getTxt(item.name, 14, "black", true );
			nameTxt.x = this.circle.width  ;
			nameTxt.y = -5;
			this.addChild(nameTxt);


		} else {

			hhh             = _h - padding;
			this.background = new PixiRoundRect(_w - padding, hhh, color, 1,this.boxRadius  );

			nameTxt = getTxt(item.name, 11, "white", true);
			nameTxt.x = 6;
			nameTxt.y = this.background.height - (nameTxt.height + 5);
			this.addChild(nameTxt);

			txt = getTxt(item.brand + " "+ item.id)
			txt.x = 6
			txt.y = nameTxt.y - (txt.height );
			this.addChild(txt);

		}


		this.addChildAt(this.background, 0);
		this.setupShadow();

	}



	setupShadow(e){


		this.shadowContainer = new PIXI.Container()
		this.shadowBox = new PixiBox(this.background.width + 10, this.background.height + 10, 0x000001)
		const filter = new PIXI.filters.BlurFilter();
		filter.blur = 15;
		filter.quality = 15;
		
		this.shadowBox.filters = [filter];
		this.shadowBox.alpha = .2
		this.shadowBox.y = 2
		this.shadowBox.x = this.background.width * .5 - this.shadowBox.width * .5;
		this.shadowContainer.addChild(this.shadowBox);
		this.shadowBox.cacheAsBitmap = true;
		this.shadowContainer.alpha = .2;
		this.addChildAt(this.shadowContainer, 2);

		if (this.item.type === "box"){
			this.shadowBox.y = this.background.y
		}


		if (this.item.type !== "box"){

			this.spin = new PIXI.Sprite.fromImage("spin");
			this.spin.anchor.set (.5, .5)
			this.spin.x = this.background.width * .5
			this.spin.y = this.background.height *.5 // - this.spin.height * .5;
			this.spin.x = this.background.width * .5
			this.spin.y = this.background.height *.5
			this.addChild(this.spin)
			this.spinTween = TweenMax.to(this.spin,.5, {rotation: Math.PI * 2, ease:"Linear.easeNone", repeat:-1});

		}


		this.loader = new PIXI.loaders.Loader();
		this.loader.add(this.item.img, "./assets/" + this.item.img);


		this.loader.on('complete', (e) => {

			this.img = new PIXI.Sprite.fromImage(this.item.img);

			if(this.item.type === "box" ){

				console.log (" Card.js > this.item.img = " , this.item.img);

				this.img = new PIXI.Sprite.fromImage(this.item.img);
				this.img.anchor.set(.5, .5)
				this.img.width = this.circle.width ;
				this.img.scale.y = this.img.scale.x;

				//this.img.y = -(this.circle.height * .5) +10;
				this.img.x = this.circle.x //this.circle.width* .5;
				this.img.y = this.circle.y //this.circle.width* .5;
				this.img.mask = this.circle

				this.addChild(this.img);



				return
			}



			this.img= new PIXI.Sprite.fromImage(this.item.img);
			this.img.height = this.background.height + 20;
			this.img.scale.x = this.img.scale.y;


			if(this.img.width > this.background.width ){
				const offXset = (this.background.width - this.img.width) * .5;
				this.img.x = offXset
			}

			if(this.img.height > this.background.height ){
				const offYset = (this.background.height - this.img.height) * .5;
				this.img.y = offYset;
			}

			if(this.img.height < this.background.height ){

			}

			if(this.img.width < this.background.width){

				this.img.scale.x = 1;
				this.img.scale.y = 1;

				this.img.width = this.background.width;
				this.img.scale.y = this.img.scale.x;

			}

			this.imageMask = new PixiRoundRect(this.background.width, this.background.height, null, 1 , this.boxRadius );
			this.addChild(this.imageMask)
			this.img.mask = this.imageMask;

			this.img.alpha = 0;
			this.addChildAt(this.img, 2);
			TweenMax.to(this.img,1, {alpha : 1 , ease:"Expo.easeOut"});
			TweenMax.killTweensOf(this.spin);
			TweenMax.to(this.spin,.3, {alpha : 0 , ease:"Expo.easeOut"});

		});



		this.loader.load();



	}



	cleanUp(){

	}

}
