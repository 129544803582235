/**
 * Created by @author @ddennis - ddennis.dk aka fantastisk.dk/works aka meresukker.dk on 12-02-2019.
 */

import React, { Component } from 'react';
import TweenMax from 'gsap';

import chevron from '../../assets/svg/outline-chevron_right-24px.svg'
import { PRIMARY_COLOR, PRIMARY_COLOR_HEX } from "../../CONSTANTS";

export default class InfoExpand extends Component {


	state = {
		open:true
	}



	toggle = () => {


			console.log (" InfoExpand.js > cle = " );
		this.setState({open:!this.state.open}, () => {

			const speed = .5
			console.log (" InfoExpand.js > what = ");
			if(this.state.open ){
				TweenMax.to(this.myElement,speed, {height:0 ,  ease:"Expo.easeOut"});
				TweenMax.to(this.icon, speed, {rotation:0,transformOrigin:'50% 30%',   ease:"Expo.easeOut"});
				//TweenMax.to(this.innerElement,speed, {y:"-100%" ,   ease:"Expo.easeOut"});
				//TweenMax.to(this.myElement,speed, {scaleY:0 ,transformOrigin:'0% 0%',   ease:"Expo.easeOut"});
				//TweenMax.to(this.innerElement ,speed, {scaleY:2, transformOrigin:'0% 0%',  ease:"Expo.easeOut"});

			}			else {

				TweenMax.to(this.myElement,speed, {height:this.innerElement.clientHeight +20,  ease:"Back.easeOut"});
				TweenMax.to(this.icon, speed, {rotation:90,transformOrigin:'50% 30%',   ease:"Expo.easeOut"});
				//TweenMax.to(this.innerElement,speed, {y:"0%" ,   ease:"Expo.easeOut"});
				//TweenMax.to(this.myElement,speed, {scaleY:1 , transformOrigin:'0% 0%',  ease:"Expo.easeOut"});
				//TweenMax.to(this.innerElement ,speed, {scaleY:1, transformOrigin:'0% 0%',  ease:"Expo.easeOut"});
			}


		})




	};


	/*height:0px;
	overflow: hidden;*/

	render(){

		const {title, body} = this.props;


		return (


		   <div className="pb-1 pb-lg-0">

			   <div onClick={this.toggle}  className="d-flex flex-row justify-content-between " style={{cursor:"pointer"}}>
				   <h5 ><b>{title}</b></h5>
				   <div className="d-flex flex-row" >
					   <span className="d-md-block d-lg-none " style={{paddingTop:6, fontSize:12, color:"rgba(0,0,0,.7)"}}> Read more... </span><img ref={(thisDiv) => {this.icon = thisDiv}} className="pb-3 mr-2 d-md-block d-lg-none" style={{color:"red"}} src={chevron} alt=""/>
				   </div>
			   </div>

			   <div className="d-md-block d-lg-none" style={{height:1, width:"100%", background:PRIMARY_COLOR_HEX, opacity:.3, marginBottom:12}}>


			   </div>

			   <div className="ex-content" ref={(thisDiv) => {this.myElement = thisDiv}}
					style={{
						overflow:"hidden",
						zIndex:500
					}}
			   >
				   <div ref={(thisDiv) => {this.innerElement = thisDiv}} >
					   <p>
						   {body}
					   </p>
				   </div>
			   </div>

		   </div>
		)
	}

}

