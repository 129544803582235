/**
 * Created by @author @ddennis - ddennis.dk aka fantastisk.dk/works aka meresukker.dk on 16-02-2019.
 */
/**
 * Created by @author @ddennis - ddennis.dk aka fantastisk.dk/works aka meresukker.dk on 08-01-2019.
 */

import React, { PureComponent } from 'react';
import Influencers from "./Influencers";
import BrandedContent from "./BrandedContent";
import GetPaid from "./GetPaid";
import InfluencerTestimoniels from "./InfluencerTestimoniels";
import Footer from "../footer/Footer";
import Firstmovers from "../front/firstmovers/Firstmovers";

export default class InfluencerWrapper extends PureComponent {

	render(){

		window.scrollTo(0, 0);

		return (
		   <div className="container-fluid">
			   <Influencers></Influencers>
			   <BrandedContent></BrandedContent>
			   <GetPaid></GetPaid>
			   <InfluencerTestimoniels/>
			   <Firstmovers></Firstmovers>
			   <Footer></Footer>
		   </div>
		)
	}

}
