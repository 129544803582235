/**
 * Created by @author @ddennis - ddennis.dk aka fantastisk.dk/works aka meresukker.dk on 10-01-2019.
 */

import * as PIXI from "pixi.js";
export const getTxt = (label = "test", size = 11, color = 'white', isBold = false , _style, _wordWrap = false, _wordWrapWidth ) =>{

	const style = _style || new PIXI.TextStyle({
		align:'left',
		breakWords:false,
		dropShadow:false,
		dropShadowAlpha:1,
		dropShadowAngle:Math.PI / 6,
		dropShadowBlur:0,
		dropShadowColor:'black',
		dropShadowDistance:5,
		fill:color,
		//fillGradientType: _const.TEXT_GRADIENT.LINEAR_VERTICAL,
		//fillGradientStops: [],
		fontFamily:'Pt Sans',
		fontSize:size,
		fontStyle:'normal',
		fontVariant:'normal',
		fontWeight:isBold ? 'bold':'normal',
		letterSpacing:0,
		lineHeight:0,
		lineJoin:'miter',
		miterLimit:10,
		padding:0,
		stroke:'black',
		strokeThickness:0,
		textBaseline:'alphabetic',
		trim:false,
		wordWrap:_wordWrap,
		wordWrapWidth:_wordWrapWidth ,
		leading:0
	});
	return new PIXI.Text(label, style);
};

