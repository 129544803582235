import React, { Component } from 'react';
import TopNavigation from "../menu/TopNavigation";

import instagramLogo from '../../assets/svg/instagram-logo.svg'
import facebookLogo from '../../assets/svg/facebook-logo-button.svg'
import youtubeLogo from '../../assets/svg/youtube-symbol.svg'

import kirsten from "../../assets/brands/kirsten_square.jpg"

import './brands.scss'

//import video from '../../assets/phone-video.mp4'
import video from '../../assets/FWnyc.mp4'
//import video from '../../assets/video_2.mp4';



export default class Brands extends Component {

	arr = [
		{
			title:"Create",
			value:"2.5"
		},
		{
			title:"Amplify",
			value:"13.5"
		},
		{
			title:"Scale",
			value:"34.0"
		}
	]




	render(){

		const items = this.arr.map((item) => {

			return (
			   <div key={item.title} className="top-numbers m-3">
				   <div className="top-numbers-value mb-1">
					   <h2><b>{item.value}<span className="number-procent">%</span></b></h2>
				   </div>
				   <div className="top-numbers-title text-right mt-1">
					   <h6>{item.title}</h6>
				   </div>
			   </div>
			)

		})


		return (



		   <div className="generic-page h-100 w-100  brands" style={{marginBottom:0}}>


				   {/*<div className="w-100 h-100 position-absolute video-header" style={{ top:0, left:0, paddingRight:15, paddingLeft:15, paddingTop:"1rem", paddingBottom:"1rem"}}>
					    <video className="w-100 h-100 " src={video} autoPlay loop playsInline muted/>
				   </div>*/}


			   <TopNavigation />



			   <div className="offwhite h-100 w-100 d-flex  position-relative" style={{height:"inherit"}}>

				   <div className={`side-menu-component d-none d-md-block `}
						style={{borderRight:"0px solid black", zIndex:100}}>

					   <div className="h-100 w-100 d-flex flex-row" >

						   <div className="h-100 side-menu d-flex flex-column justify-content-end"
								style={{minWidth:90, borderRight:"1px solid rgba(0,0,0,.3)"}}>

							   <div className="social-icons h-30 d-flex flex-column justify-content-around align-items-center ">

								   <svg width="23px" height="23px">
									   <path fillRule="evenodd" fill="rgb(0, 47, 199)"
											 d="M11.500,-0.001 L23.000,11.500 L11.500,23.000 L-0.000,11.500 L11.500,-0.001 Z"/>
								   </svg>
								   <svg width="23px" height="23px">
									   <path fillRule="evenodd" fill="rgb(0, 47, 199)"
											 d="M11.500,-0.001 L23.000,11.500 L11.500,23.000 L-0.000,11.500 L11.500,-0.001 Z"/>
								   </svg>
								   <a href="https://www.instagram.com/finewires/"><img src={instagramLogo} alt="instagram"/></a>
								   <a href="https://www.facebook.com/fine.wires"><img src={facebookLogo} alt=""/></a>
								   <img src={youtubeLogo} alt=""/>

							   </div>

						   </div>
					   </div>
				   </div>


				   <div className="h-100 w-100 d-flex flex-column justify-content-between position-relative" style={{zIndex:50}} >

					   <div className="w-100 d-flex align-self-center" >
					   </div>

					   <div className="w-100 d-flex align-self-center" >

						   <div className="w-100 d-flex justify-content-center" >
							   <div className="p-2 p-md-0 section-headline text-center " >
								   <h1>Why we are different?</h1>
								   <p className="mt-3" style={{fontSize:"1.2rem", marginBottom:0}}>
									   <i>
										   Unlike any other influencer-platform FineWires offer brands to seed their
										   brand massages with firstmover influencers only
									   </i>
								   </p>

								   <div className="d-flex flex-row justify-content-center">

									   {items}

								   </div>

							   </div>
						   </div>
					   </div>

					   <div className="w-100 p-1"  style={{background:"#e5eaf9"}}>

							   <div className=" position-relative" style={{top:-60 , left:0}} >
								   <div className="position-absolute" >
									   <h1 style={{fontSize:120}}>“</h1>
								   </div>
							   </div>


							   <div className=" d-flex  flex-column flex-sm-row p-md-3 ">
								   <img  style={{width:114, height:114}} className="ml-sm-4 pb-2 pb-md-0 align-self-center align-self-md-start rounded-circle" src={kirsten} alt=""/>
								   <div className="ml-lg-2 d-flex flex-column justify-content-center" >

									   <p className="text-center kirsten-quote-text"  >
										   <i>
											   Firstmovers set the trend <span className="d-inline-block">for future market behavior.</span>
										   </i>
									   </p>
								   </div>
							   </div>
					   </div>
				   </div>

			   </div>

		   </div>

		)
	}

}

/*
adasda*/
