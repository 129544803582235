/**
 * Created by @author @ddennis - ddennis.dk aka fantastisk.dk/works aka meresukker.dk on 04-02-2019.
 */
import React, { Component } from 'react';
import './maria-black-case.scss'
import SimpleReveal from "../animation/SimpleReveal";
import ParallaxRowItems from "../animation/ParallaxRowItems";

import mb from '../../assets/brands/case/mb.jpg'
import mbCase from '../../assets/brands/case/bigcase.jpg'
import storyOne from '../../assets/brands/case/story_1.jpg'
import storyTwo from '../../assets/brands/case/story_2.jpg'
import storyThree from '../../assets/brands/case/story_3.jpg'

export default class MariaBlackCase extends Component {

	state = {yoffset:-1};

	componentDidMount(){
		var rect = this.containerElement.getBoundingClientRect();
		this.setState({yoffset:this.containerElement.offsetTop, containerHeight:this.containerElement.clientHeight})
	}

	render(){

		const resClass = "pl-5 pr-5 pt-4 pt-sm-2 p-sm-4 pl-md-5 p-md-2";

		return (

		   <div ref={el => { this.containerElement = el }} className="generic-page maria-black-case mb-1 ">

			   <SimpleReveal asRenderProp={true}>
				   {
					   ({isEntered}) => {

						   let yyy = 0;
						   let hhh = 0;
						   if (this.containerElement){
							   yyy = this.containerElement.offsetTop;
							   hhh = this.containerElement.clientHeight;
						   }

						   return (

							  <div className="offwhite  h-100 " style={{height:"inherit"}}>

								  <div className="row equal h-100">

									  <div className="col-12 h-100 col-md-6">

										  <img className="img-fluid mw-100 mh-100"	 src={mbCase} alt=""/>

									  </div>





									  <div className="col-12 h-100 col-md-5 mr-auto  mt-3   ">

										  <div className="pl-1 d-flex flex-column justify-content-center pl-2 pr-4 mb-3" >

											  <div className="page-headline-left mb-4" style={{paddingLeft:0, paddingRight:0, marginLeft:0, marginRight:0}}>

												  <p style={{marginBottom:0}}>
													  <i>
														  Case Story
													  </i>
												  </p>
												  <h2>Maria Black jewellery</h2>
											  </div>

											  <div>
												  <h5><b>Campaign goal</b></h5>
												  <p>Maria Black Jewellery activated FineWires influencer in Denmark to
													  create brand awareness and social content around a range of
													  signature
													  items in
													  order to boost Christmas sales.
												  </p>

											  </div>

											  <div>

												  <h5><b>Campaign details</b></h5>

												  <p>
													  Maria Black collaborated with FineWires to find firstmover micro-
													  and
													  macro
													  influencers in the Copenhagen area with the ability to mix & match
													  existing
													  styles
													  with Maria Blacks high profile signature products
												  </p>
											  </div>

											  <div>
												  <h5><b>Approach</b></h5>
												  <p>Maria Black Jewellery activated FineWires influencer in Denmark to
													  create
													  brand
													  awareness and social content around a range of signature items in
													  order to
													  boost
													  Christmas sales.
												  </p>
											  </div>

											  <div>
												  <h5><b>Results</b></h5>
												  <p>The 14 influencers posted in total 28 stories and 14 beautiful
													  images
													  and received an average engagement rate of <b>8,1%</b>, where the
													  best
													  engagement rate
													  for one post was <b>impressive 16,4%</b>.
													  The eye-catching Maria Black signiture products got 7.344 likes
													  and
													  comments from the <b>91.934 validated true followers</b>.
												  </p>
											  </div>
										  </div>
									  </div>

								  </div>

							  </div>

						   )

					   }
				   }

			   </SimpleReveal>
		   </div>

		)
	}

}
