/**
 * Created by @author @ddennis - ddennis.dk aka fantastisk.dk/works aka meresukker.dk on 20-02-2019.
 */
import React, { Component } from 'react';
import { PRIMARY_COLOR_HEX } from "../../../CONSTANTS";
import { useSpring, animated } from 'react-spring'

const calc = (x, y, el) => {

	const xx = ((el.clientWidth)/2 - ( x - el.offsetLeft ) ) ;
	return [xx  , y - 350 / 2]
	//return [x - window.innerWidth / 2, y - 350 / 2]
}

const trans1 = (x, y) => `translate3d(${x / 20}px,${y / 30}px,0)`;

function FirstmoverItem(obj){

	let element = null;
	const {item} = obj;

	const [props, set] = useSpring(() => ({xy:[0, 0], config:{mass:2, tension:800, friction:100}}))

	return (

	   <div ref={(thisDiv) => {element = thisDiv}}  className="col-6 col-lg-3 w-100 h-100 mt-4 mb-4 item" style={{}}

			onMouseLeave={() => {
				set({xy:[0, 0]})
			}}

			onMouseMove={({clientX:x, clientY:y}) => {
				return (
				   set({xy:calc(x, y, element)})
				)
			}}>

		   <div className="dot-pattern" style={{top:item.patternY, left:item.patternX }}>
		   </div>


		   <div className="item-inner h-100 float-right position-relative" style={{ paddingBottom:0, zIndex:100 , transform:`translate3d(0px,${item.yOffset}px,0)`}} >


			   <animated.div className="w-100  " style={{transform:props.xy.interpolate(trans1), }}>

				   <div className="h-100 w-100 " >
					   <img className="img-fluid mw-100 mh-100 " src={item.img} alt="" />
				   </div>

			   </animated.div>


			   <div className="h-100 d-flex flex-row position-relative name-tag" >

				   <div className="position-absolute " style={{top:0, left:item.txtOffset}}>
					   <p className="head" ><b>{item.name}</b></p>
					   <p>{item.description}</p>
				   </div>

				   {/*<div className="d-flex position-absolute justify-content-center align-items-center" style={{
					   right:5,
					   background:PRIMARY_COLOR_HEX,
					   borderRadius:"50%",
					   height:50,
					   width:50,
					   marginTop:-10
				   }}>
					   <h6 style={{color:"white", fontSize:16, paddingTop:8}}><b>5,5</b></h6>
				   </div>*/}



			   </div>



		   </div>



	   </div>
	)

}

export default FirstmoverItem

/*export default class FirstmoverItem extends Component {

	calc = (x, y) => [x - window.innerWidth / 2, y - window.innerHeight / 2]
	trans1 = (x, y) => `translate3d(${x / 5}px,${y / 5}px,0)`
	/!*trans2 = (x, y) => `translate3d(${x / 8 + 35}px,${y / 8 - 230}px,0)`
	trans3 = (x, y) => `translate3d(${x / 6 - 250}px,${y / 6 - 200}px,0)`
	trans4 = (x, y) => `translate3d(${x / 3.5}px,${y / 3.5}px,0)`*!/

	render(){

		const {item} = this.props;
		const d = Math.round(Math.random() * 15) + 1;
		const [props, set] = useSpring(() => ({ xy: [0, 0], config: { mass: 20, tension: 800, friction: 100 } }))

		return (

			   <div className="col-6 col-lg-3 mb-4 mb-lg-0 w-100 h-100 item" onMouseMove={({clientX:x, clientY:y}) => {
				   return (
					  set({xy:this.calc(x, y)})
				   )
			   }}>

			   <animated.div className=" h-100 d-flex flex-column pt-4 " style={{transform: props.xy.interpolate(this.trans1)}}>

				   <img className="w-75 align-self-center" src={item.img} alt="" style={{transform:`rotate(${item.d}deg)`}}/>

				   <div className="h-100 pt-4 pl-3 pb-2 pr-1 d-flex flex-row justify-content-between position-relative "  >

					   <div>
						   <p>JENS PETER JENSEN </p>
						   <p>33 YRS, COPENTHAGEN</p>
					   </div>

					   <div className="d-flex position-absolute justify-content-center align-items-center" style={{ right:5, background:PRIMARY_COLOR_HEX , borderRadius:"50%", height:50, width:50, marginTop:-10 }}>
						   <h6 style={{color:"white", fontSize:16, paddingTop:8}}><b>5,5</b></h6>
					   </div>

				   </div>


			   </animated.div>


		   </div>
		)
	}

}*/
/*
style={{background:"#e2e8f6", paddingBottom:0}}*/
