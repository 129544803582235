/**
 * Created by @author @ddennis - ddennis.dk aka fantastisk.dk/works aka meresukker.dk on 26-10-2018.
 */

import React, { Component } from 'react';
import { Button } from 'reactstrap';
import Measure from 'react-measure'

import './Front.scss'

import TopNavigation from "../menu/TopNavigation";
import FrontItems from "./FrontItems";
import SideMenu from "../menu/SideMenu";
import { Link } from "react-router-dom";

import video from '../../assets/FWnyc.mp4'

export default class Front extends Component {

	render(){

		return (

		   <div className="front " style={{height:"auto"}}>

			   <TopNavigation color="white" />

			   <div className=" w-100 " style={{height:"auto"}}>

				   <div className="dot-pattern" style={{ }}>
				   </div>


				   <div className="vh-100 d-flex justify-content-center position-relative" >

					   <div className="video-wrap ">
						   <video src={video} autoPlay muted loop id="">
						   </video>
					   </div>

					   <div className="row " style={{}}>
						   <div className="col-sm-12 col-md-7 col-lg-8 ">

							   <div className="h-100 headline  pl-4 pl-md-5" >

								   <div className=" ">
									   <p>Grow your brand across social media</p>
									   <h1>We match brands with <span
										  className="d-inline-block">firstmover influencers</span></h1>

									   <Link to="/influencers">

										   <Button className="mb-md-0 mr-2 pl-lg-4 pr-lg-4 pl-md-5 pr-md-5 "
												   color="primary">INFLUENCERS</Button>
									   </Link>

									   <Link to="/brands">
										   <Button className="pl-lg-4 pr-lg-4 pl-md-1 pr-md-1 pl-md-5 pr-md-5"
												   color="primary">BRANDS</Button>
									   </Link>
								   </div>

							   </div>

						   </div>
					   </div>

				   </div>
			   </div>
		   </div>

		)
	}

}

/*<div className="front generic-page h-100 w-100 ">
		<TopNavigation/>

		<div className="offwhite w-100 " style={{height:"inherit"}}>

			<div className="dot-pattern" style={{ }}>
			</div>

			<div className="color-overlay w-100 h-100"></div>

			<div className="row h-100" style={{}}>
				<div className="col-sm-12 col-md-7 col-lg-8 " >

				 <div className="video-wrap position-relative w-100 h-100">
					 <video src={video} autoPlay muted loop id="">
					 </video>
				 </div>

				 <div className="content headline h-100 d-flex flex-column justify-content-center ">

					 <div className="">
						 <p>Grow your brand across social media</p>
						 <h1>We match brands with <span className="d-inline-block">firstmover influencers</span></h1>

						 <Link to="/influencers">

							 <Button className="mb-md-0 mr-2 pl-lg-4 pr-lg-4 pl-md-5 pr-md-5 "
									 color="primary">INFLUENCERS</Button>
						 </Link>

						 <Link to="/brands">
							 <Button className="pl-lg-4 pr-lg-4 pl-md-1 pr-md-1 pl-md-5 pr-md-5" color="primary">BRANDS</Button>
						 </Link>
					 </div>

				 </div>
				</div>
			</div>



		</div>
	</div>
*/
