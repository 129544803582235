/**
 * Created by @author @ddennis - ddennis.dk aka fantastisk.dk/works aka meresukker.dk on 22-02-2019.
 */

import React, { Component } from 'react';
import instagramLogo from "../../assets/svg/instagram-logo.svg";
import facebookLogo from "../../assets/svg/facebook-logo-button.svg";
import youtubeLogo from "../../assets/svg/youtube-symbol.svg";

import './side-menu.scss'

export default class SideMenu extends Component {

	render(){

		const background = this.props.background || "";

		const resClass = background ? "col-md-3 col-lg-3 col-xl-3 " : "col-md-1 col-lg-1 col-xl-1 "

		return (

		   /* it's 1 col wide  */
		   <div className={`side-menu-component d-none d-md-block  ${ resClass }`}
				style={{borderRight:"0px solid black"}}>

			   <div className="h-100 w-100 d-flex flex-row" >

				   <div className="h-100 side-menu d-flex flex-column justify-content-end"
						style={{width:"120px", borderRight:"1px solid rgba(0,0,0,.3)"}}>

					   <div className="social-icons h-30 d-flex flex-column justify-content-around align-items-center ">

						   <svg width="23px" height="23px">
							   <path fillRule="evenodd" fill="rgb(0, 47, 199)"
									 d="M11.500,-0.001 L23.000,11.500 L11.500,23.000 L-0.000,11.500 L11.500,-0.001 Z"/>
						   </svg>
						   <svg width="23px" height="23px">
							   <path fillRule="evenodd" fill="rgb(0, 47, 199)"
									 d="M11.500,-0.001 L23.000,11.500 L11.500,23.000 L-0.000,11.500 L11.500,-0.001 Z"/>
						   </svg>
						   <a href="https://www.instagram.com/finewires/"><img src={instagramLogo} alt="instagram"/></a>
						   <a href="https://www.facebook.com/fine.wires"><img src={facebookLogo} alt=""/></a>
						   <img src={youtubeLogo} alt=""/>

					   </div>

				   </div>


				   {background ?
					  (
						 <div className="h-100 w-100 head-image" style={{backgroundImage:`url(${background})`}}>

						 </div>
					  )
					  : null
				   }


			   </div>

		   </div>

		)
	}

}
