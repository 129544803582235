import * as PIXI from 'pixi.js'

export class PixiTriangle extends PIXI.Graphics {


	constructor(_w, _h, _c, _a){
		super();

		var triangleWidth = _w;
		var triangleHeight = _h;
		var triangleHalfway = triangleWidth / 2;
		const c = _c || 0xCC0000;
		const a = _a || 1;

		this.clear();
		// draw triangle
		this.beginFill( c , 1 );
		//this.lineStyle(0 , c, a);
		this.moveTo(triangleWidth, 0);
		this.lineTo(triangleHalfway, triangleHeight);
		this.lineTo(0, 0);
		this.lineTo(triangleHalfway, 0);
		this.endFill();

	}

}
