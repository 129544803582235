/**
 * Created by @author @ddennis - ddennis.dk aka fantastisk.dk/works aka meresukker.dk on 14-11-2018.
 */
import React, { Component } from 'react';
import TopNavigation from "../menu/TopNavigation";

import  LogoPath from '../../assets/svg/instagram-logo_outline.svg'
import  {ReactComponent as Logo} from '../../assets/svg/instagram-logo_outline.svg'


import './contact.scss'

export default class Contact extends Component {

	constructor(props){
		super(props)
	}

	render(){

		const k = {
			g:{
				fill:"red"
			}
		}

		return (
			<div className="container-fluid contact" >

				<div className="generic-page h-100 w-100 " >

					<TopNavigation/>

					<div className="offwhite h-100 w-100  "  >


						<div className="row h-100" style={{}}>

							<div className="pl-md-4 pl-0 col-12 d-flex justify-content-center flex-column ">
								<div className="align-self-center">
									<h1 >Get in touch</h1>
									<p className=" m-0 text-center">Antonigade 4 1105 Copenhagen</p>
									<p className="text-center m-0">info@finewires.network</p>

								</div>

							</div>
						</div>


					</div>
				</div>


			</div>
		)
	}

}
