import React, { Component } from 'react';
import { Row, Col } from "reactstrap";
import mariablackLogo from "../../assets/mariablack-logo.png"
import Reveal from "../animation/Reveal"
import "./testimonial.scss"
import Waypoint from 'react-waypoint';
import SimpleReveal from "../animation/SimpleReveal";

export default class Testimonial extends Component {

	state = {isEntered:false}

	render(){

		return (

		   <div className="" >

			   <SimpleReveal>
				   <Row className="testimonial " style={{marginLeft:0, marginRight:0}}>

					   <Col sm={12} md={6} className="testimonial-image ">

					   </Col>

					   <Col sm={12} md={6} className="text-center txt ">
						   <div>
							   <img src={mariablackLogo} alt=""/>
						   </div>
						   <h5><i>
							   Working with FineWires gives us the tools to find influencers to advocate for
							   our brand.
							   But
							   not just any influencers – the right influencers. The FirstMover-rating of all
							   influencers
							   on the platform is our garantee that the effect of our influencer campaigns
							   will trickle
							   down and create longtail impact.</i>
						   </h5>
						   <p>Maria Black Jewelery - CMO, Jens Jensen</p>
					   </Col>
				   </Row>
			   </SimpleReveal>
		   </div>

		)
	}

}
