

import React from 'react';
import "./menu-icon.scss"
import menuicon from  "../../assets/svg/baseline-menu-24px.svg"


export default class MenuIcon extends React.PureComponent {
	render(){
		return (
			<div onClick={this.props.toggleMenu} className="app-menu">
				<svg width="50px" height="50px">
					<g>
					<path fillRule="evenodd"  strokeWidth="2px" stroke="rgb(0, 39, 164)" fill="rgb(255, 255, 255)"
						  d="M26.000,3.499 C38.426,3.499 48.500,13.573 48.500,25.999 C48.500,38.426 38.426,48.500 26.000,48.500 C13.574,48.500 3.500,38.426 3.500,25.999 C3.500,13.573 13.574,3.499 26.000,3.499 Z"/>
					</g>
					<g  transform="translate(18 21)">
						<path fillRule="evenodd" fill="rgb(0, 47, 199)"
							  d="M-0.000,11.000 L-0.000,9.844 L16.000,9.844 L16.000,11.000 L-0.000,11.000 ZM-0.000,4.844 L16.000,4.844 L16.000,6.000 L-0.000,6.000 L-0.000,4.844 ZM-0.000,-0.000 L16.000,-0.000 L16.000,1.156 L-0.000,1.156 L-0.000,-0.000 Z"/>
					</g>
				</svg>
			</div>
		)
	}
}