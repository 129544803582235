import React, { Component } from 'react';
import { Container, Row, Col } from 'reactstrap';
import './case.scss'
import Waypoint from "react-waypoint";
import { Spring, animated } from "react-spring";
import SimpleReveal from "../../animation/SimpleReveal";

export default class Case extends Component {

	render(){

		return (
		   //padding-top removed to aligne the tool image right on top

		   <div className="h-100 w-100  mb-3">

			   <SimpleReveal>
				   <div className="h-100 w-100 " style={{background:"white"}}>

					   <div ref={this.props.innerRef} className="row case w-100 h-100 " style={{marginLeft:0}}>

						   <Col xs={0} sm={0} md={1} className="d-none d-md-block " style={{background:"white"}}></Col>

						   <Col xs={12} sm={8} md={5} className="" style={{background:"white"}}>

							   <div className="h-100 d-flex flex-column justify-content-center">

								   <div className="">
									   <p style={{marginBottom:0}}><b>Good content grabs your attention</b></p>
									   <h1 className="">Great content<br/>inspires action.</h1>
								   </div>
								   <div className="">
									   <p className="lead" style={{paddingRight:30}}>
										   So how do you harness the power of authentic, meaningful content, in which
										   consumers
										   will
										   engage? Influencer marketing has proven to be the answer. Influencer
										   Marketing is
										   branded
										   content created by consumers, for consumers. It’s trusted and valuable,
										   rather than
										   interruptive and contrived.
									   </p>
								   </div>
							   </div>
						   </Col>

						   <Col xs={12} sm={4} md={6} className="images">
							   {/*<div className="bubble" style={{marginLeft:"-75px", marginTop:"10%"}}>
								   <div
									  className="text-center d-flex flex-column h-100 content justify-content-center ">
									   <h5>15</h5>
									   <p>Influencers</p>
								   </div>
							   </div>

							   <div className="bubble" style={{marginLeft:"50%", marginTop:"35%"}}>
								   <div
									  className="text-center d-flex flex-column h-100 content justify-content-center ">
									   <h5>78%</h5>
									   <p>Millenials</p>
								   </div>
							   </div>


							   <div className="bubble" style={{marginLeft:"25%", marginTop:"60%"}}>
								   <div
									  className="text-center d-flex flex-column h-100 content justify-content-center ">
									   <h5>176K</h5>
									   <p>Reach</p>
								   </div>
							   </div>

							   <div className="bubble" style={{marginLeft:"75%", marginTop:"80%"}}>
								   <div
									  className="text-center d-flex flex-column h-100 content justify-content-center ">
									   <h5>5,2%</h5>
									   <p>Engagement</p>
								   </div>
							   </div>
*/}
						   </Col>
					   </div>


				   </div>


			   </SimpleReveal>
		   </div>

		)
	}

}
