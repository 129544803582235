/**
 * Created by @author @ddennis - ddennis.dk aka fantastisk.dk/works aka meresukker.dk on 08-01-2019.
 */


import React, { PureComponent } from 'react';
import Front from "../front/Front";
import Tool from "../tool/Tool";
import Case from "./case/Case";
import Testimonial from "../testimonial/Testimonial";
import Firstmovers from "./firstmovers/Firstmovers";
import Footer from "../footer/Footer";
import { DEBUG } from "../../CONSTANTS";


export default class FrontWrapper extends PureComponent {


	render(){

		window.scrollTo(0, 0);

		return (

		   <div className="container-fluid">
			   <Front></Front>
			   <Tool></Tool>
			   <Case></Case>
			   <Testimonial></Testimonial>
			   <Firstmovers></Firstmovers>
			   <Footer></Footer>
		   </div>

		)
	}

}
