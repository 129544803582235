/**
 * Created by @author @ddennis - ddennis.dk aka fantastisk.dk/works aka meresukker.dk on 16-02-2019.
 */
import React, { Component } from 'react';

import dummyIcon from '../../assets/svg/influencers.svg'
import bg1 from '../../assets/influencers/brandedTerms_bg_1.jpg'
import SimpleReveal from "../animation/SimpleReveal";
import { ReactComponent as MoneyIcon } from "../../assets/svg/money.svg";
import { ReactComponent as TransparaencyIcon } from "../../assets/svg/transparency.svg";
import { ReactComponent as GrowthIcon } from "../../assets/svg/growth.svg";
import brandedContent from '../../assets/influencers/brandedContent.jpg'
import './branded-content.scss';

export default class BrandedContent extends Component {

	render(){

		const iconSize = 35

		return (

		   <div className="generic-page branded-content ">
			   <SimpleReveal>
				   <div className="offwhite h-100">

					   <div className="row h-100 w-100 mb-5 " style={{marginLeft:0}}>

						   <div className="col-12 col-md-6 h-100  ">

							   <div className="h-100 d-flex justify-content-center">
								   <img className="img-fluid align-self-center" src={brandedContent} alt=""/>
							   </div>

						   </div>

						   <div className="col-12 col-md-5 pl-4 pr-4 pr-md-0 pl-md-0">

							   <div className=" h-100 d-flex flex-column justify-content-center">

								   <div className="my-headline mb-4 mt-3 p-0" style={{}}>
									   <p style={{marginBottom:0}}>You are the boss</p>
									   <h1>Branded content <span className="d-inline-block">on your terms</span></h1>
								   </div>

								   <div className="mb-1">

									   <div className="d-flex flex-row ">
										   <MoneyIcon className="mySvg " style={{width:iconSize, height:iconSize}}/>
										   <h6 className="align-self-center pt-1 ml-3"><b>EARN MONEY</b></h6>
									   </div>

									   <p className="">
										   Our main focus is to bring you great sponsors. From the trendiest startups to
										   the
										   biggest brands, choose from a constant flow of opportunities across multiple
										   categories.
									   </p>
								   </div>

								   <div className="mb-1">

									   <div className="d-flex flex-row">
										   <TransparaencyIcon className="mySvg "
															  style={{width:iconSize, height:iconSize}}/>
										   <h6 className="align-self-center pt-1 ml-3">
											   <b>FULL TRANSPARENCY</b>
										   </h6>
									   </div>

									   <p className="mb-1">
										   FineWires is FREE to join without contracts or gimmicks and you NEVER give up
										   rights to your social media accounts! You decide when and who you work with.
									   </p>
								   </div>

								   <div className="">

									   <div className="d-flex flex-row mb-2">
										   <GrowthIcon className="mySvg "
													   style={{width:iconSize + 4, height:iconSize + 4}}/>
										   <h6 className="align-self-center pt-2 ml-3">
											   <b>PROFESSIONAL GROWTH </b>
										   </h6>
									   </div>

									   <p className="">
										   FineWires helps you turn your passion into a business by connecting you with
										   brands looking to collaborate and create amazing branded content.
									   </p>
								   </div>

							   </div>
						   </div>
					   </div>

				   </div>
			   </SimpleReveal>
		   </div>

		)
	}

}
