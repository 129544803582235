/**
 * Created by @author @ddennis - ddennis.dk aka fantastisk.dk/works aka meresukker.dk on 30-01-2019.
 */
import * as PIXI from 'pixi.js'
import { getTxt } from "../Txt";
import TweenMax from 'gsap';
import { PixiBox } from "../ddennis/PixiBox";

export class GraphBtnContainer extends PIXI.Container {

	constructor(btnArr, _app, _infoBox, _bars){
		super()

		this.app     = _app;
		this.bars     = _bars;
		this.infoBox = _infoBox;

		let ypos = 0;
		let xpos = -140;

		this.inter = new PIXI.interaction.InteractionData()

		this.y = Math.round(this.y)

		this.btnsArr = []
		btnArr.forEach((item, index) => {

			const obj = this.createBtn(item, index)
			const btn = obj.c;

			btn.interactive = true;
			btn.buttonMode  = true;

			btn.mouseActive = false;

			btn.pointerover = (e) => {

				btn.mouseActive = true
				obj.txt.tint = 0x0033c7
				TweenMax.to(obj.whiteBtn, .2, {alpha:1, ease:"Expo.easeOut"});
				this.showInfo(e, btn, index);

				this.emit("SELECT", { index:index })
			};

			//
			btn.mousemove = (e) => {
				if (btn.mouseActive){
					this.ticker(e, btn)
				}
			};


			btn.pointerout = () => {
				btn.mouseActive = false
				obj.txt.tint = 0xFFFFFF;
				//obj.back.tint = 0x333333
				TweenMax.to(obj.whiteBtn, .4, {alpha:0, ease:"Expo.easeOut"});
				this.hideInfo();
				this.emit("DE_SELECT")
			};

			btn.pointertap = function (e){
				console.log(" GraphBtnContainer.js > WHAT	= ");
				TweenMax.to(obj.whiteBtn, .4, {alpha:1, ease:"Expo.easeOut"});
			};

			this.btnsArr.push(obj)
			this.addChild(btn)
		})

	}



	showInfo = (e, btn, index) => {
		const k = e.data.getLocalPosition(btn);
		this.infoBox.show(index);

	};



	//---------------------------------------------------------------------------------------

	ticker = (e, btn) => {

		const mouseData = e.data.global;
		const y = mouseData.y + 20 + this.infoBox.height > this.appHeight ? (mouseData.y - this.infoBox.height) : mouseData.y;
		const x = mouseData.x + 20 + this.infoBox.width > this.appWidth ? (mouseData.x - (this.infoBox.width + 20) ) : mouseData.x + 20;
		const point = {x:Math.round(x), y:Math.round(y + 20)}
		this.infoBox.respos(point)

	};



	//---------------------------------------------------------------------------------------

	hideInfo = (e) => {

		this.app.ticker.remove(this.ticker, this);
		this.infoBox.hide();

	};



	// RESIZE
	//---------------------------------------------------------------------------------------

	resize(w, h, graphScale){

		this.appHeight = h;
		this.appWidth = w;

		const arr = [
			{x:115, y:405},
			{x:200, y:310 },
			{x:270, y:210 },
			{x:340, y:110 },
			{x:280 , y:30 },
		]


		this.btnsArr.forEach((item, index) => {

			const p = arr[index];
			const btn = item.c;

			const xx = index == 4 ? p.x : p.x - 175;
			btn.x = xx* graphScale  ;
			btn.y = p.y* graphScale  ;

		})


	}





	createBtn(item, index){

		const c = new PIXI.Container();

		const pad = 60;

		const s = (index+1).toString() + ". " + item.name
		const txt = getTxt(s, 16, 'white', true);

		const btnWidth = 115 + pad
		//const btnWidth = 100

		const btn = new PIXI.Graphics();
		btn.lineStyle(2, 0xFFFFFF, 1);
		btn.beginFill(0x0033c7, 1);
		btn.drawRect(0, 0, btnWidth, 50);
		btn.endFill();

		const whiteBtn = new PIXI.Graphics();
		whiteBtn.beginFill(0xFFFFFF, 1);
		//whiteBtn.lineStyle(2, 0xFFFFFF, 1);
		whiteBtn.drawRect(0, 0, btnWidth, 50);
		whiteBtn.endFill();

		/*const cross = this.drawCross();
		cross.x     = (btn.width - (cross.width * 3));
		cross.y     = btn.height * .5 - cross.height * .5;*/

		txt.y = Math.round(btn.height * .5 - txt.height * .5);
		txt.x = Math.round(btn.width * .5 - txt.width * .5)
		c.addChild(btn);
		whiteBtn.alpha = 0
		c.addChild(whiteBtn);
		//c.addChild(cross);
		c.addChild(txt);
		//return {c:c, whiteBtn:whiteBtn, txt:txt, cross:cross};
		return {c:c, whiteBtn:whiteBtn, txt:txt};

	}

	drawCross(s = 10){

		const half      = s / 2;
		const lineWidth = 2;

		const c = new PIXI.Container()

		const lineOne = new PIXI.Graphics();
		lineOne.beginFill(0x0FFFFFF, 1);
		lineOne.drawRect(half - lineWidth * .5, 0, lineWidth, s);
		lineOne.endFill();

		const lineTwo = new PIXI.Graphics();
		lineTwo.beginFill(0x0FFFFFF, 1);
		lineTwo.drawRect(0, half - lineWidth * .5, s, lineWidth);
		lineTwo.endFill();

		c.addChild(lineTwo);
		c.addChild(lineOne);
		return c;

	}

	layoutMobile(){

		let xpos = 0;
		let ypos = 0;
		this.btnsArr.forEach((item, index) => {

			const btn = item.c;
			if (index % 3 == 0 && index != 0){
				ypos = Math.round(ypos + btn.height + 10)
				xpos = 50;
			}

			btn.x            = xpos;
			btn.y            = ypos;
			item.cross.alpha = 0
			xpos             = xpos + btn.width + 10
		})

	}

}
