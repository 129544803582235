/**
 * Created by @author @ddennis - ddennis.dk aka fantastisk.dk/works aka meresukker.dk on 25-01-2019.
 */

import React, { Component } from 'react';
import { Col } from "reactstrap";
import Measure from 'react-measure';
import './interest-graph.scss'

import HeadIcon from '../../assets/svg/tem_LorryIkon-24.svg';
import SimpleReveal from "../animation/SimpleReveal";

export default class InterestGraph extends Component {

	state = {
		dimensions:{
			width:-1,
			height:-1
		}
	};

	componentDidMount(){

		this.pixiElement.appendChild(this.props.pixiApp.view);
		this.props.pixiApp.renderer.resize(0,0);
		this.props.mainView.switchView("graph");
		this.props.pixiApp.start();
	}

	componentWillUnmount(){
		this.props.pixiApp.stop();
	}

	render(){

		const w = this.state.dimensions.width;
		const h = this.state.dimensions.height;


		if (this.props.pixiApp && w !== -1){

			// bootstrap applies a -15 margin to both left and right
			const bootstrapTotalMargin = 30;
			this.props.pixiApp.renderer.resize(w - bootstrapTotalMargin, this.state.dimensions.height  );

			this.props.mainView.resize()
		}

		return (


		   <div className="generic-page interest-graph d-flex flex-column w-100 "
				style={{paddingBottom:0}}>

			   <div className="row">
				   <div className="col-12 text-center page-headline-white mt-0 mt-md-2 p-3" >
					   <h2 className="">Seeding within "The right half"</h2>
					   <p className="text-center mr-auto ml-auto head-txt " style={{maxWidth:750}}>
						   <i>
							   The diffusion of your brand message depends on who your message is seeded with. At
							   FineWires we have carefully curated the influencers that matters the most. In a CoLab
							   with FirstMove each influencer is screened based
							   on <b>Personality</b>, <b>Socialization</b> abilities, <b>Values</b>, <b>Behavior</b> and <b>Interest</b> in
							   a specific community.
						   </i>
					   </p>
				   </div>
			   </div>


				   <Measure

					  client
					 	onResize={(contentRect) => {

						  this.setState({dimensions:contentRect.client})
					  }}>

					   {({measureRef}) => {
						   return (
							  <div ref={measureRef} className="row d-flex flex-grow-1"  >
								  <div className="col-12 d-flex flex-grow-1 pixi-container " ref={measureRef} ref={(thisDiv) => {this.pixiElement = thisDiv}}  >
								  </div>
							  </div>
						   )
					   }
					   }

				   </Measure>


			   <div className="row d-lg-none d-sm-block interest-mobile " style={{paddingLeft:15, paddingRight:15}}>


				   <div className="pl-2 pr-2 pt-4 pb-4 offwhite">

					   {/*<div className="col-12 pr-lg-0" style={{paddingRight:0}}>
						   <div className="d-flex flex-row">
							   <div style={{width:35, height:35, paddingBottom:0, marginLeft:0, marginRight:15}}>
								   <img className="mw-100 mh-100" src={HeadIcon} alt=""/>
							   </div>

							   <div className="d-flex align-items-center" style={{paddingTop:5}}>
								   <h5><b>1. Personality</b></h5>
							   </div>
						   </div>
						   <p>
							   asdasdas dasd sdasdad asdasdas dasd sdasdad asdasdas dasd sdasdad asdasdas dasd
							   sdasdad
						   </p>

					   </div>*/}

					   <div className="col-12">
						   <h5><b>1. Personality</b></h5>
						   <p>At the core a firstmover’s personality is characterized by the will to – innovate, accept risk, change and act.</p>
					   </div>
					   <div className="col-12">
						   <h5><b>2. Socialization</b></h5>
						   <p>The second screening layer of a firstmover is the degree of socialization – the social network and the influencer’s reach and “see it here first”-footprint in the social sphere.</p>
					   </div>
					   <div className="col-12">
						   <h5><b>3. Values</b></h5>
						   <p>The third screening layer represents the values and the everchanging interpretation of values that create the human needs of tomorrow.</p>
					   </div>
					   <div className="col-12">
						   <h5><b>4. Behavior</b></h5>
						   <p>Guided by the change in needs, behavior is the visible result of the firstmover’s request and demand after new products and services in the mass market.</p>
					   </div>
					   <div className="col-12">
						   <h5><b>5. Interest</b></h5>
						   <p>The fifth layer is more of a segmentation than a screening layer and it allows for a category specialization. As an interest is an inextricable element of value redemption, the firstmover’s take will often stand out.</p>
					   </div>
				   </div>

			   </div>

		   </div>

		)
	}
}
