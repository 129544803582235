/**
 * Created by @author @ddennis - ddennis.dk aka fantastisk.dk/works aka meresukker.dk on 01-02-2019.
 */
import React, { Component } from 'react';

import {
	Carousel,
	CarouselItem,
	CarouselControl,
	CarouselIndicators,
	CarouselCaption
} from 'reactstrap';

import card from '../../assets/brands/card.png'
import cardProfileOne from '../../assets/brands/card2.png'
import cardProfileTwo from '../../assets/brands/card3.png'

export default class CardCarousel extends Component {


	state = { activeIndex: 0 };

	items = [
		{
			src:card,
			altText:'Slide 1',
			caption:'Slide 1'
		},
		{
			src:cardProfileOne,
			altText:'Slide 2',
			caption:'Slide 2'
		},
		{
			src:cardProfileTwo,
			altText:'Slide 3',
			caption:'Slide 3'
		}
	];

	onExiting = () => {
		this.animating = true;
	};

	onExited = () => {
		this.animating = false;
	};

	next = () => {
		if (this.animating) return;
		const nextIndex = this.state.activeIndex === this.items.length - 1 ? 0 : this.state.activeIndex + 1;
		this.setState({activeIndex:nextIndex});
	};

	previous = () => {
		if (this.animating) return;
		const nextIndex = this.state.activeIndex === 0 ? this.items.length - 1 : this.state.activeIndex - 1;
		this.setState({activeIndex:nextIndex});
	};

	goToIndex = (newIndex) => {

		if (this.animating) return;
		this.setState({activeIndex:newIndex});

	};

	render(){

		const {activeIndex} = this.state;


		const slides = this.items.map((item) => {
			return (
			   <CarouselItem className="w-100"
				  onExiting={this.onExiting}
				  onExited={this.onExited}
				  key={item.src}
			   >
				   <img className=" mw-100" src={item.src} alt={item.altText}/>
				   {/*<CarouselCaption captionText={item.caption} captionHeader={item.caption}/>*/}
			   </CarouselItem>
			);
		});

		return (

		   <Carousel
			  activeIndex={activeIndex}
			  next={this.next}
			  previous={this.previous}
		   >
			   <CarouselIndicators items={this.items} activeIndex={activeIndex} onClickHandler={this.goToIndex}/>
			   {slides}
			   <CarouselControl direction="prev" directionText="Previous" onClickHandler={this.previous}/>
			   <CarouselControl direction="next" directionText="Next" onClickHandler={this.next}/>
		   </Carousel>

		)
	}


}
