import React, { Component } from 'react';
import Waypoint from 'react-waypoint';
import { Keyframes, Spring, animated } from 'react-spring'
import { Container, Row, Col } from 'reactstrap';
import toolImage from '../../assets/tool.png'
import "./Tool.scss";
import SimpleReveal from "../animation/SimpleReveal";

export default class Tool extends Component {

	render(){

		return (

		   <div className="tool generic-page  " style={{paddingBottom:0, minHeight:450, height:"auto", paddingTop:0}} >

			   <SimpleReveal>

				<div className="h-100 d-flex flex-column" style={{}}>

				   <div className="row mt-0 mt-md-2">
					   <div className="col-12  mt-0 mb-3 mb-md-0 mt-md-0 " >
						   <div className="text-center mt-3 page-headline-white ">
							   <h2 className="">Manage your own campaign</h2>
							   <p className="text-center m-auto " style={{marginBottom:0, maxWidth:600}}>
								   When the younger audience change the way they consume content,
								   brands need to change the way they communicate with them.
								   We connect brands and digital influencers – and create great
								   content.
							   </p>
							   <p style={{paddingTop:10}}><u>Try it today</u></p>
						   </div>
					   </div>
				   </div>



					<div className="row d-flex flex-grow-1 " >
						<div className="col-12 d-flex flex-column justify-content-end" >
							<div className="text-center">
								<img className="mh-100 mw-100" src={toolImage} alt=""/>
							</div>
						</div>
					</div>
				   {/*<Row className="d-flex flex-grow-1 ">
					   <Col className="tool-image-container d-flex justify-content-end">
						   <div className="tool-image">
											</div>
						   <img src={toolImage} alt=""/>
					   </Col>
				   </Row>*/}

				</div>
			   </SimpleReveal>

		   </div>

		)
	}

}

/*

<Row className="tool h-100 p-3">
				<Col className="my-content  " >
					<div className="text-center mt-3 page-headline ">
						<h2 className="">Manage your own campaign</h2>
						<p className="text-center">When the younger audience change the way they consume content, brands need to change the way they communicate with them.
							We connect brands and digital influencers – and create great content.
						</p>
						<p><u>Try it today</u></p>
					</div>

					<div className="tool-image">

					</div>
				</Col>
			</Row>
 */
