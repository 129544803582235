import React, { Component } from 'react';

import { BrowserRouter as Router, Route, Switch, Link } from "react-router-dom";
import * as PIXI from 'pixi.js';
import MenuIcon from "./components/menu/MenuIcon";
import MenuOverlay from "./components/menu/MenuOverlay";
import { Transition, animated } from 'react-spring/renderprops';
import Marketplace from "./components/marketplace/Marketplace";
import Contact from "./components/contact/Contact";
import FrontWrapper from "./components/front/FrontWrapper";
import ReactGA from 'react-ga';
import { MainView } from "./components/canvas/MainView";
import BrandWrapper from "./components/brands/BrandWrapper";
import InfluencerWrapper from "./components/influencers/InfluencerWrapper";

import './styles/styles.scss';
import "./styles/components/app.scss"
import './styles/components/page.scss'
import { DEBUG } from "./CONSTANTS";

if (process.env.NODE_ENV !== 'production'){
	import ('./styles/size-debug.scss').then((something) => {
		console.log("SIZE DEBUG LOADED");
	});
}


ReactGA.initialize('UA-139974710-1', {testMode:DEBUG});


class AnimatedRoute extends React.Component {

	currentPath = ""

	leave = item => async (next, cancel) => {
		this.props.toggleSc()
		//await next({scrollTop:0})
		await next({opacity:0.1, transform:'translate3d(-200px,0px,0)'}, true);
		this.props.toggleSc()
		//await next({ opacity: 0.5 })
		//await next({ opacity: 1 , delay:1000})
		//await next({ opacity: .2 })
		//await next({ opacity: 0.1 }, true)
		//await next({ height: 100 }, true) // Inform Keyframes that is is the last frame
	};

	render(){


		return (
		   <Route
			  render={({location}) => {

				  const p = location.pathname;
				  if(p !== this.currentPath ){
					  this.currentPath = p;
					  ReactGA.pageview(location.pathname);
				  }


				  return (
					 <Transition
						native
						items={location}
						keys={location => location.pathname}
						from={{opacity:0, transform:'translate3d(200px,0,0)'}}
						enter={{opacity:1, transform:'translate3d(0,0px,0)', config:{delay:100}}}
						config={{tension:250, friction:28}}
						leave={this.leave}>
						 {location => style => <animated.div scrollTop={100} className="app-animated-container"
															 style={style}>{this.props.children(location)}</animated.div>}
					 </Transition>
				  )
			  }
			  }
		   />
		)
	}
}

class App extends Component {

	el = React.createRef();

	state = {
		menuOpen:false,
		sc:0,
		updateScroll:false,
		app:null
	};

	toggleMenu = () => {
		this.setState({menuOpen:!this.state.menuOpen})
	};

	toggleScroll = () => {

		this.setState({updateScroll:!this.state.updateScroll})
	};

	componentDidMount(){

		const app = new PIXI.Application(window.innerWidth, window.innerHeight, {
			transparent:true,
			resolution:1,
			autoResize:false,
			antialias:true, /*forceCanvas:true*/
			roundPixels:true
		});

		// should only be set onces
		app.interactive = false;

		/*const graphView = new GraphView(app);
		graphView.name = "graph-view";*/

		const mainView = new MainView(app);
		mainView.name  = "main-view";

		app.stage.addChild(mainView);

		// save the canvas stuff in state -
		this.setState({app:app, mainView:mainView});
		mainView.init(this.state); //= new MainView(this.props.pixiApp, this.state);

	}

	render(){

		const y = this.el.current ? this.el.current.scrollTop : 0;

		return (

		   <div className="h-100 w-100 app-wrapper">

			   <Router>
				   <div className="app">

					   <MenuOverlay toggleMenu={this.toggleMenu} menuOpen={this.state.menuOpen}/>
					   <MenuIcon toggleMenu={this.toggleMenu}/>

					   <AnimatedRoute ypos={y} toggleSc={this.toggleScroll}>

						   { location => {


							   return (

								   <Switch location={location}>
								   <Route exact={true} path='/' render={(props) => <FrontWrapper pixiApp={this.state.app}
																								 mainView={this.state.mainView}/>}/>
								   <Route exact={true} path='/brands'
								   render={props => <BrandWrapper pixiApp={this.state.app}
																  mainView={this.state.mainView}/>}/>
								   <Route exact={true} path='/marketplace' render={(props) => <Marketplace/>}/>
								   <Route exact={true} path='/influencers' render={(props) => <InfluencerWrapper/>}/>
								   <Route exact={true} path='/contact' render={(props) => <Contact/>}/>
								   </Switch>
							   )
						   }}
					   </AnimatedRoute>
				   </div>
			   </Router>
		   </div>
		);
	}
}

export default App;

