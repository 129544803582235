/**
 * Created by @author @ddennis - ddennis.dk aka fantastisk.dk/works aka meresukker.dk on 21-02-2019.
 */
import React, { Component } from 'react';

import bgPic from '../../assets/influencers/brandedTerms_bg_2.png'

import SimpleReveal from "../animation/SimpleReveal";

import './get-paid.scss'
import getPaidImage from '../../assets/influencers/getPaid.png'
import TweenMax from 'gsap'
import brandedContent from "../../assets/influencers/brandedContent.jpg";

export default class GetPaid extends Component {


	myElements = [];

	itemsArr = [

		{head:"Signup", body:"You have 1000+ followers and like to share engaging branded content" , x:-30, y:-10},
		{head:"Get Seen", body:"We invite you to join campaigns for great brands that matches your profile and you will receive a campaign brief describing the task and payment" , x:-10, y:10},
		{head:"Create", body:"Put your heart into creating inspiring content and upload it for finale approval from the brand." , x:80, y:20},
		{head:"Get paid", body:"If you’re content is approved you can share it in your social feed and receive payment for a job well done!" , x:60, y:-70}

	];


	enter = () => {

		TweenMax.staggerFromTo(this.myElements, .6,{y:100, alpha:0}, {y:0, alpha:1, delay:.3 , ease:"Expo.easeOut"}, .1)

	}


	render(){

		const list = this.itemsArr.map((item, index) => {

			const bgClass = "item-" + (index + 1)
			const count = "0"+(index +1);

			return (

			   <div ref={li => this.myElements[index] = li} key={index} className="col-6 ml-auto mr-auto col-md-6 col-lg-3  mb-1 item  ">

				   <div className="position-absolute" style={{top:item.y , left:item.x, zIndex:20}}>
					   <img src={bgPic} alt=""/>
				   </div>

				   <div className={`item-inner h-100 ${bgClass} `}  >

					   <div className="d-flex w-100 justify-content-center pt-md-4 pb-md-2 pt-4 pb-4" >

						   <div className="w-100 text-center">
							   <h2 style={{color:"white"}}><b>{count}</b></h2>
							   <h6 className="text-center "><b>{item.head}</b></h6>
						   </div>
					   </div>


					   <p className="text-center pl-2 pr-2 pr-lg-4 pl-lg-4">
						   {item.body}
					   </p>

				   </div>
			   </div>
			)
		})

		return (

		   <div className="generic-page get-paid">
			   <SimpleReveal enter={this.enter}>


				   <div className="offwhite h-100 ">

					   <div className=" ml-4 mr-4 h-100">

						   <div className="row pt-5" style={{}}>
							   <div className="col">
								   <div className="page-headline" style={{paddingBottom:0}}>
									   <h1>
										   Get paid for the posts you share
									   </h1>
									   <p>
										   You are four steps away from becoming a professional influencer. We value
										   your creativity, respect your time and do not expect you to do it for free.
									   </p>
								   </div>
							   </div>
					½	   </div>


						   <div className="row " style={{}}>

							   <div className="col-1"></div>
							   <div className="col-12 col-md-3  d-flex flex-column justify-content-center">

								   <div className="text-center mb-3" >
									   <h5 >01 <b>Signup</b></h5>
									   <p>{this.itemsArr[0].body}</p>
								   </div>

								   <div className="text-center mb-3" >
									   <h5>02 <b>Get Seen</b></h5>
									   <p>{this.itemsArr[1].body}</p>

								   </div>

							   </div>


							   {/*Center phone image*/}
							   <div className="col-12 col-md-4 order-first order-md-2 mb-2" >
								   <div className="  ">
									   <img className="img-fluid ml-0 align-self-center" src={getPaidImage } alt=""/>
								   </div>
							   </div>

							   <div className="col-12 col-md-3 d-flex flex-column justify-content-center order-last  ">
								   <div className="text-center mb-3" >
									   <h5 >03 <b>Create</b></h5>
									   <p >{this.itemsArr[2].body}</p>
								   </div>

								   <div className="text-center mb-3" >
									   <h5>04 <b>Get Paid</b></h5>
									   <p>{this.itemsArr[3].body}</p>

								   </div>
							   </div>


						   </div>
					   </div>
				   </div>
			   </SimpleReveal>
		   </div>
		)
	}

}
