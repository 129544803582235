/**
 * Created by @author @ddennis - ddennis.dk aka fantastisk.dk/works aka meresukker.dk on 06-02-2019.
 */
import * as PIXI from 'pixi.js'
import { PixiBox } from "../ddennis/PixiBox";
import { getTxt } from "../Txt";
import { PRIMARY_COLOR } from "../../../CONSTANTS";
import TweenMax from 'gsap'
export class BottomBar extends PIXI.Container {

	constructor(_w, _h, _barDateArr){
		super();

		this.bottomBar   = new PixiBox(_w, _h, 0xFFFFFF);
		this.addChild(this.bottomBar);

		this.barsDataArr = _barDateArr;
		this.barArr = [];
		//this.drawLabels();

	}



	drawLabels(){

		let xpos = 0;
		this.barsDataArr.forEach((item, index) => {

			const con = new PIXI.Container()
			const txt = getTxt(item.label, 13, PRIMARY_COLOR, true);

			txt.y = Math.round (this.bottomBar.height *.5 - txt.height * .5)

			const lineHeight = 400
			const b = new PixiBox(2, lineHeight, PRIMARY_COLOR)
			b.alpha = 1;
			b.x = txt.width * .5;
			b.y = -b.height;

			this.barArr.push({item:con, txt:txt, line:b});

			con.addChild(b);
			con.addChild(txt);
			this.addChild(con);

		})


	}


	resize(w, xoffset, graphScale){

		this.bottomBar.redraw(w);

		/*const arr = [
			{x:110, y:385},
			{x:250, y:245},
			{x:370, y:110},
			{x:520, y:5},
			{x:800, y:20},
		];*/


		const arr = [
			{x:30, y:385},
			{x:80, y:245},
			{x:220, y:110},
			{x:485, y:5},
			{x:790, y:20},
		];

		this.barArr.forEach((obj, index) => {

			const {item , txt, line } = obj
			item.alpha = 0;

			const xpos = ((arr[index].x * graphScale) + xoffset) ;

			item.x = xpos - 50;

			let toAlpha = 1;
			if(index === 0 ){
				line.visible = false;
				txt.x = -50 *graphScale ;
			};

			if(index === 1 ){
				txt.x =   70 *graphScale ;
			};

			if(index === 2 ){
				txt.x = 130 *graphScale ;
			};

			if(index === 3 ){
				txt.x = 150 *graphScale ;
				//line.x = line.x + 1;
				line.visible = false;
				toAlpha = .5;
			}

			if(index === 4 ){
				txt.x = 120 *graphScale ;
				line.visible = false;
				toAlpha = .5;
			}


			TweenMax.to(item,.5, {alpha:toAlpha, x:xpos, delay:index/10, ease:"Expo.easeOut"});
			line.scale.y = 0
			TweenMax.to(line.scale,.5, {y:1 , delay:(index/10) + .5, ease:"Expo.easeOut"});

		})


	}

}
