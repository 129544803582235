/**
 * Created by @author @ddennis - ddennis.dk aka fantastisk.dk/works aka meresukker.dk on 31-01-2019.
 */

import React, { Component } from 'react';
import CardCarousel from "./CardCarousel";
import './info-card.scss'
import SimpleReveal from "../animation/SimpleReveal";
import InfoExpand from "./InfoExpand";

class InfoCard extends Component {

	render(){

		return (

		   <div className="generic-page infocard  " style={{height:"auto"}}>
			   <SimpleReveal>
				   <div className="offwhite h-100  ">

					   <div className="row">

						   <div className="col-12 mt-3 ">
							   <div className="text-center page-headline">
								   <h2>A match made in heaven</h2>
								   <p className="text-center">
									   <i>
										   An extensive profile is created for each influencers to secure the best match
										   between brand message and influencer characteristics.
									   </i>
								   </p>
							   </div>
						   </div>

					   </div>

					   <div className="row side-txt " style={{}}>

						   <div className=" col-md-12 col-lg-3  ">

							   <div className="row side-txt-left side-txt-row align-items-lg-center h-100 ">

								   <div className="col-12 pl-5 pr-4  pr-lg-0 ">
									   <InfoExpand
										  title="1. Firstmover rating"
										  body="Each influencer is curated based on the person’s set of values and how these match the value set of a Firstmover. At FineWires we only host influencers that are Firstmovers, Next-movers or Early Mainstream."
									   ></InfoExpand>
								   </div>

								   <div className="col-12 pl-5 pr-4 pr-lg-0">
									   <InfoExpand
										  title="2. True followers"
										  body="Fake followers are not a problem as long as you do not pay for them. At FineWires each influencer is subjected to a cleansing process where followers are validated, so that you only pay for the true followers."
									   ></InfoExpand>
								   </div>

							   </div>
						   </div>

						   <div className="col-xs-12 col-lg-6 order-first order-lg-2 text-center"
								style={{background:""}}>
							   <CardCarousel></CardCarousel>

						   </div>

						   <div className="col-sm-12 col-lg-3 order-last " style={{marginTop:-15}}>

							   <div className="row side-txt-row side-txt-right justify-content-between h-100">

								   <div className="col-12 pl-5 pr-4 pl-lg-0 ">

									   <InfoExpand
										  title="3. Community"
										  body="In a product oriented campaign we secure a high degree of product interest among followers by seeding the brand message based on the influencer’s interests."
									   ></InfoExpand>

								   </div>

								   <div className="col-12 pl-5 pr-4 pl-lg-0">
									   <InfoExpand
										  title="4. Firstmover values"
										  body="The strongest influencer campaigns are the once based on common values between brands and the influencers. When influencers are asked to communicate shared values and not only a product – that’s where the magic happens!"
									   ></InfoExpand>

								   </div>
								   <div className="col-12 pl-5 pr-4 pl-lg-0">
									   <InfoExpand
										  title="5. Rated by brands"
										  body="Some influencers are easy to work with – some are not. Every time we work with an influencer – the influencer is rated by the brand"
									   ></InfoExpand>
								   </div>
							   </div>

						   </div>

					   </div>
				   </div>

			   </SimpleReveal>
		   </div>

		);
	}
}

export default InfoCard;
