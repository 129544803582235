/**
 * Created by @author @ddennis - ddennis.dk aka fantastisk.dk/works aka meresukker.dk on 05-02-2019.
 */
import React, { Component } from 'react';
import Waypoint from "react-waypoint";
import TweenMax from 'gsap'

export default class SimpleReveal extends Component {

	state = {isEntered:false}
	visible = false;

	/*topEnter = (o) => {
		if (this.props.debug){
			console.log(" reveal.js > SHOW = ", o);
		}

		if (o.previousPosition === "above"){
			return
		}

		this.show(o)
		this.setState({isEntered:true})

	};*/

	/*topLeave = (o) => {
		if (o.currentPosition === "below"){
			TweenMax.to(this.myElement, .5, {alpha:0, y:0, ease:"Expo.easeOut"});
			//TweenMax.set(this.myElement , {alpha:0, y:200});
		}
	}
*/
/*	show = (o) => {
		const startYpos = o.previousPosition == "above" ? -100 : 200;
		TweenMax.fromTo(this.myElement, .7, {alpha:0, y:startYpos, ease:"Expo.easeOut"}, {
			alpha:1,
			y:0,
			ease:"Expo.easeOut"
		});
	}*/
/*
	bottomLeave = (o) => {
		if (this.props.debug){
			console.log(" reveal.js > HIDE = ", o);
		}

		if (o.currentPosition === "above"){
			let yyy = o.previousPosition == "above" ? -200 : 200;
			TweenMax.to(this.myElement, .5, {alpha:0, y:-200, ease:"Expo.easeOut"});
			this.setState({isEntered:false})
			return
		}
	};

	bottomEnter = (o) => {
		if (o.previousPosition === "above" || o.currentPosition === "inside"){
			let yyyy = -200
			TweenMax.to(this.myElement, .5, {alpha:1, y:0, ease:"Expo.easeOut"});
			this.setState({isEntered:false})
		}
	}*/


	componentDidMount(){
		//TweenMax.set(this.myElement , {alpha:0, y:200});
		//TweenMax.set(this.myElement , {alpha:0, y:200});
	}



	out = (o) => {
		if (this.props.debug){
			console.log(" reveal.js > OUT = ", o);
		}

		TweenMax.set(this.myElement, {alpha:0});
		this.setState({isEntered:false})
	};




	in = (o) => {
		if (this.props.debug){
			console.log(" reveal.js > IN = ", o);
		}

		this.setState({isEntered:true})

		if(this.props.enter ){
			this.props.enter()
		}

		const ypos = o.previousPosition === "below" ? 150 : -150;

		TweenMax.set(this.myElement, {alpha:0,  y:ypos});
		TweenMax.to(this.myElement, .7, {
			alpha:1,
			y:0,
			ease:"Expo.easeOut",

			delay:.3
		});

	};


	render(){

		const {children, asRenderProp, debug} = this.props;
		const isEntered                       = this.state.isEntered;

		const isDevelopment = debug && window.process && window.process.env && window.process.env.NODE_ENV && window.process.env.NODE_ENV !== 'production';

		return (

		   <>
			   {isDevelopment ? (<div style={{background:"red", width:"100%", height:2}}></div>) : null}
			   <Waypoint bottomOffset={0} topOffset={0} onEnter={this.in} onLeave={this.out}>


				   <div style={{height:'inherit'}}>
					   <div style={{height:'inherit'}} ref={(thisDiv) => {this.myElement = thisDiv}}>
						   {asRenderProp ? children({isEntered:isEntered}) : children}
					   </div>
				   </div>

			   </Waypoint>
			   {isDevelopment ? (<div className="w-100" style={{background:"red", width:"100%", height:2}}></div>) : null}


		   </>
		)
	}
}
