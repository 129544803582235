/**
 * Created by @author @ddennis - ddennis.dk aka fantastisk.dk/works aka meresukker.dk on 08-02-2019.
 */
import React, { Component } from 'react';

import './footer.scss'

export default class Footer extends Component {

	render(){

		return (

		   <div className="page-footer ">

				   <div className="row " style={{height:50}}>
					   <div className="col">
						   <div className="d-flex justify-content-center text-center">
							   <p>© 2019 Finewires | info@finewires.network | Antoniegade 4 1105 Copenhagen</p>
						   </div>
					   </div>

				   </div>
		   </div>

		)
	}

}
