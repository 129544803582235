/**
 * Created by @author @ddennis - ddennis.dk aka fantastisk.dk/works aka meresukker.dk on 08-02-2019.
 */

import React, { Component } from 'react';
import { TweenMax } from "gsap/TweenMax";

import ScrollListener from "react-scroll-listen";
import { Button } from "reactstrap";
import './front-items.scss'
import { PRIMARY_COLOR, PRIMARY_COLOR_HEX } from "../../CONSTANTS";
import { ReactComponent as InstagramIcon } from "../../assets/svg/instagram-logo_outline.svg";

import splashImage from '../../assets/splash.png'

//import '../../../public/assets'

export default class FrontItems extends Component {

	//myTween    = new TimelineLite({paused:true});
	state          = {containerWidth:0};
	myElements     = [];
	splashElements = [];
	itemElements   = [];

	itemsArr = [

		/* ---- TOP   ----  */

		{w:0, h:0, top:0, speed:1.5, col:"col-5 d-none d-md-block col-md-3  ", img:""},

		{w:169, h:184, top:100, speed:1.5, col:"col-6 d-none d-sm-block col-sm-6 col-md-4 ", img:"./assets/food.jpg"},

		{w:271, h:290, top:0, speed:1.5, col:"col-12 col-sm-6 col-md-5 ", img:"./assets/profile_6.jpg"},

		/* ----  MIDDLE  ----  */


		{w:0, h:0, top:0, speed:1.5, col:"col-2 d-xs-block col-lg-2 ", img:""},

		{w:230, h:264, top:0, speed:1.5, col:"col-3 d-none d-lg-block col-lg-4", img:"./assets/maria_1.jpg"},

		{w:170, h:202, top:0, speed:1.5, col:"col-3 d-none d-lg-block col-lg-3", img:"./assets/ikea.jpg"},

		{w:170, h:240, top:0, speed:1.5, col:"col-10 col-md-12 col-lg-3", img:"./assets/profile_5.jpg"},

		/* ---- BOTTOM  ----  */
		{w:0, h:0, top:0, speed:1.5, col:"col-2 d-none d-lg-block col-lg-5", img:""},

		{w:170, h:240, top:30, speed:1.5, col:"col-6 col-md-8 col-lg-3", img:"./assets/profile_3.jpg"},

		{w:270, h:380, top:0, speed:1.5, col:"col-12 d-none d-lg-block col-lg-4 ", img:"./assets/profile_2.jpg"},

		{w:300, h:120, top:60, speed:1.5, col:"col-6 d-sm-none d-xs-block ", img:"./assets/profile_7.jpg"},

	];

	componentDidMount(){

		const cw = this.containerElement.clientWidth / 3;
		this.setState({containerWidth:cw})
		this.elHeight = this.containerElement.clientHeight;
		//this.fadeIn();

	}

	onPageScroll = (value) => {

	};

	start = () => {

		this.fadeIn();
	};

	fadeIn = () => {

		this.myElements.map((item, index) => {
			const el   = item.el
			const spec = this.itemsArr[index];
			//TweenMax.set(el,  {y:155, alpha:0});
			//TweenMax.to(el, .3, {y:0, alpha:1, delay:(index/5)  , ease:"Back.easeOut"});
			//TweenMax.fromTo(el, .8, {y:150, alpha:0}, {y:spec.top , alpha:1, delay:(index/5), force3D:true  , ease:"Expo.easeOut"});
			//TweenMax.fromTo(el, .8, {y:150, alpha:0}, {y:spec.top , alpha:1, delay:(index/5), force3D:true  , ease:"Expo.easeOut"});
			//TweenMax.to(item, .8, {scaleX:0 , scaleY:0, delay:(index/5), force3D:true  , ease:"Expo.easeOut"});
			//TweenMax.to(item, .5, {scaleX:0 , transformOrigin:"100% 0%", delay:(index/10) + 1, force3D:true  , ease:"Expo.easeOut"});
			//TweenMax.to(item, .5, {scaleX:0 ,scaleY:0 ,  delay:(index/10) + 1, force3D:true  , ease:"Expo.easeOut"});

		})

	};

	itemOver = (index) => {
		//console.log (" FrontItems.js > index = " , index);
		const item      = this.myElements[index];
		const splash    = this.splashElements[index];
		const container = this.itemElements[index];

		TweenMax.set(container, {zIndex:1000});
		TweenMax.to(item, .2, {scaleX:1, scaleY:1.2, transformOrigin:"50% 0%", force3D:true, ease:"Expo.easeOut"});
		TweenMax.to(splash, .2, {scaleY:1, autoAlpha:1, force3D:true, ease:"Expo.easeOut"});

	};

	itemOut = (index) => {
		//console.log (" FrontItems.js > index = " , index);

		const item      = this.myElements[index];
		const splash    = this.splashElements[index];
		const container = this.itemElements[index];

		TweenMax.set(container, {zIndex:1});
		TweenMax.to(item, .3, {scaleX:0, scaleY:.1, force3D:true, ease:"Expo.easeOut"});
		TweenMax.to(splash, 0, {scaleY:1, autoAlpha:0, force3D:true, ease:"Expo.easeOut"});
	};

	render(){

		const items = this.itemsArr.map((item, index) => {

			const itemClass = item.col + " pt-2 pb-2 item w-100 position-relative ";
			const img       = "url(" + item.img + ")";

			return (
			   <div ref={li => this.itemElements[index] = li} key={index} className={itemClass} style={{}}>


				   {item.img ? (

				      <>
						  <div
							 /*onMouseOver={(e) => {
								 this.itemOver(index, e)
							 }}
							 onMouseOut={(e) => {
								 this.itemOut(index, e)
							 }}*/
							 className="w-100 item-inner "
							 style={{backgroundImage:img, backgroundRepeat:"no-repeat", height:item.h}}>

							  <div className="item-txt w-100 h-100 pb-2 pl-2 d-flex flex-column justify-content-end" style={{}}>
								  <p>asdads</p>
							  </div>
						  </div>


					{/*
						  <div
							 ref={li => this.myElements[index] = li}
							 className="position-absolute" style={{
							  background:PRIMARY_COLOR_HEX,
							  width:"100%",
							  height:item.h + 60,
							  top:0,
							  left:0,
							  zIndex:-1,
							  transform:"scale(0,0)"
						  }}>
							  <div className="h-100 pb-4 pl-3 d-flex flex-column justify-content-end">
								  <div>
									  <h6 className="text-center">
										  <b>TYPE: UNICORN <br/>
											  TOTAL REACH: 464K <br/>
											  MEDIA: INSTA, YOUTUBE, FB
										  </b>
									  </h6>

								  </div>

							  </div>

						  </div>*/}
						  {/*<div
							 ref={li => this.splashElements[index] = li}
							 className="position-absolute "
							 style={{width:"100%", height:"100%", top:-110, left:-140, zIndex:-2, opacity:0}}>
							  <img src={splashImage} alt=""/>
						  </div>*/}

					  </>

					   ) : null}



			   </div>

			)
		})

		return (

		   <div ref={el => { this.containerElement = el }}	className="w-100 front-items "	style={{zIndex:100,}}>

			   <div className="row w-100 h-100 d-flex "
					style={{position:"relative", top:-60}}
					ref={el => { this.containerElement = el }}
			   >
				   {items}
			   </div>


		   </div>
		)
	}

};
