/**
 * Created by @author @ddennis - ddennis.dk aka fantastisk.dk/works aka meresukker.dk on 07-02-2019.
 */
import * as PIXI from 'pixi.js'
import { PixiCircle } from "../ddennis/PixiCircle";
import { getTxt } from "../Txt";
import { PRIMARY_COLOR } from "../../../CONSTANTS";
import TweenMax from 'gsap';

export class Hints extends PIXI.Container {

	constructor(_barDataArr){
		super();
		this.barDataArr = _barDataArr;

		this.hints = []
		this.draw()
	}

	draw(){

		/*const a        = this.barDataArr.concat([])
		const reversed = a.reverse()*/

		this.barDataArr.forEach((item, index) => {

			const hint  = new PIXI.Container()
			const buble = new PixiCircle(12, 0xFFFFFF)
			const pulse = new PixiCircle(20, 0xFFFFFF)
			pulse.name  = "pulse";
			const txt   = getTxt((index + 1).toString(), 12, PRIMARY_COLOR, true)
			txt.x       = -Math.round(txt.width * .5)+1;
			txt.y       = -Math.round(txt.height * .5);

			hint.addChild(pulse);
			hint.addChild(buble);
			hint.addChild(txt);
			this.hints.push(hint);

			this.addChild(hint)

		})
	}

	hide(){
		this.visible = false;
		this.hints.forEach((item) => {

			const pulse = item.getChildByName('pulse')
			TweenMax.killTweensOf(pulse)

		})
	}

	show(){
		this.visible = true;


		this.hints.forEach((item, index) => {

			/*const pulse = item.getChildByName('pulse')
			pulse.alpha = 1;
			pulse.scale.set(0,0)
			TweenMax.to(pulse, 1, {alpha:.2, yoyo:true, repeat:-1, repeatDelay:.3, delay:(index/10)  , ease:"Expo.easeOut"});
			TweenMax.to(pulse.scale, 1, {x:1.2, y:1.2, yoyo:true, repeatDelay:.3, repeat:-1, delay:(index/10) , ease:"Expo.easeOut"});*/

			const pulse = item.getChildByName('pulse')



			pulse.alpha = 1;
			pulse.scale.set(0,0)
			TweenMax.killTweensOf(pulse);
			TweenMax.killTweensOf(pulse.scale);
			TweenMax.to(pulse, 1, {alpha:.0,  repeat:-1, repeatDelay:1, delay:(index/5)  , ease:"Expo.easeOut"});
			TweenMax.to(pulse.scale, 1, {x:1.4, y:1.4,  repeatDelay:1, repeat:-1, delay:(index/5) , ease:"Expo.easeOut"});
			//TweenMax.to(item, .5, {y:5, yoyo:true, repeatDelay:1, repeat:-1, delay:(index/5) , ease:"Back.easeOut"});

			/*pulse.alpha = 1;
			pulse.scale.set(0,0)
			TweenMax.to(pulse, 1, {alpha:0, yoyo:true, repeat:-1, repeatDelay:.3, delay:(index/10)  , ease:"Expo.easeOut"});
			TweenMax.to(pulse.scale, 1, {x:1.5, y:1.5, yoyo:true, repeatDelay:.3, repeat:-1, delay:(index/10) , ease:"Expo.easeOut"});*/

		})

	}

	resize(appWidth, appHeight, graphScale){

		console.log (" Hints.js > graphScale = " , graphScale);

		const arr = [
			{x:120, y:385},
			{x:255, y:245},
			{x:370, y:110},
			{x:520, y:5},
			{x:800, y:20}
		];

		this.hints.forEach((item, index) => {

			const p = arr[index];
			item.x  = p.x * graphScale
			//item.y = p.x

		})

	}

}
