/**
 * Created by @author @ddennis - ddennis.dk aka fantastisk.dk/works aka meresukker.dk on 05-11-2018.
 */
import React, { Component } from 'react';
import { Row, Col } from "reactstrap";
import "./firstmovers.scss"
import SimpleReveal from "../../animation/SimpleReveal";
import TopNavigation from "../../influencers/Influencers";


import FeatOne from '../../../assets/featured/first_1.png';
import FeatTwo from '../../../assets/featured/first_2.png';
import FeatThree from '../../../assets/featured/first_3.png';
import FeatFour from '../../../assets/featured/first_4.png';



import TweenMax from 'gsap'
import { PRIMARY_COLOR_HEX } from "../../../CONSTANTS";
import FirstmoverItem from "./FirstmoverItem";

export default class Firstmovers extends Component {

	myElements = [];

	items = [
		{name:"Cassandra Malmberg" , description:"Female, Copenhagen, 20 yrs", img:FeatOne,			yOffset:-25,	h:398, 	txtOffset: 30, patternX:"10%",  patternY:-55},
		{name:"Lea Maria Øhlers Damgaard" , description:"Female, Copenhagen, 20 yrs", img:FeatTwo,	yOffset:10, 	h:337,	txtOffset: 10, patternX:"60%",  patternY:200},
		{name:"Danielle Schmidt" , description:"Female, Copenhagen, 25 yrs", img:FeatThree, 		yOffset:-40,	h:328, 	txtOffset: 16, patternX:30,  patternY:10},
		{name:"Olivia Aamund" , description:"Female, Copenhagen, 19 yrs", img:FeatFour,				yOffset:0,	h:403, 	txtOffset: 0,  patternX:202,  patternY:250}
	];





	render(){

		const list = this.items.map((item, index) => {

			return (
			   <FirstmoverItem  key={index} item={item}/>
			)

		})

		return (

		   <div className="generic-page firstmovers ">

			   <SimpleReveal >


				   <div className="offwhite h-100 d-flex flex-column mb-0 mb-md-0">

					   <div className="row h-100" style={{}}>
						   <div className="col-12 mb-5 mb-md-0">
							   <div className="text-center mt-3 page-headline ">
								   <h2 className="">Featured Firstmovers</h2>
								   <p className="text-center" style={{marginBottom:0}}>
									   All influencer on FineWires are curated based on their FirstMover rating. In
									   order to
									   be
									   featured on the platform an influencers will need to answer our
									   FirstMover-questionaire
									   design by the trend company FirstMove. The value of content, engagement and
									   impressions
									   will depend on the curation of the influencers and their audience.
								   </p>
								   <p style={{paddingTop:10}}>
									   <u>Try it today</u>
								   </p>
							   </div>




						   </div>

						   <div className="col-12 mb-4 mb-md-5 " >

							   <div className="row featured  ">
								   {list}
							   </div>

						   </div>

					   </div>



					   {/*<div className="container d-flex flex-grow-1">

						   <div className="row  "  style={{background:"red"}}>
							   {list}
						   </div>

					   </div>*/}


				   </div>


			   </SimpleReveal>
		   </div>

		)
	}
}

