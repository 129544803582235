/**
 * Created by @author @ddennis - ddennis.dk aka fantastisk.dk/works aka meresukker.dk on 11-03-2019.
 */
import React, { Component } from 'react';
import './the-colab.scss'

import firstmoveLogo from '../../assets/svg/firstmoveLogo.svg'
import SimpleReveal from "../animation/SimpleReveal";

export default class TheColab extends Component {



	render(){

		return (
		   <div className="the-colab ">
			   <SimpleReveal>
			   <div className="row  " style={{}}>

				   <div className="col-12 col-md-3 h-100 w-100 p-4">

					   <div className="d-flex justify-content-center">
						   <a href="http://firstmove.dk/" target="_blank" className="square ">
							   <div className="content">
								   <div className="logo h-100 d-flex justify-content-center">
									   <div className="firstmove-logo align-self-center">
									   </div>
								   </div>
							   </div>
						   </a>
					   </div>

				   </div>

				   <div className="col-md-9 col-12  align-self-center">
					   <div className="p-3 p-md-0">
						   <h2><b>The Colab</b></h2>
						   <p>
							   What is Firstmove - Since 2013 Kirsten Poulsen has worked with firstmovers to gain future
							   based
							   consumer insights to drive the innovation process for brands. This knowledge is now used
							   in the
							   screening process of influencers at FineWires, where only influencers with a high
							   firstmover-rating is signed on.
						   </p>
					   </div>

				   </div>
			   </div>
			   </SimpleReveal>
		   </div>


		)
	}

}
