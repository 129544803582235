/**
 * Created by @author @ddennis - ddennis.dk aka fantastisk.dk/works aka meresukker.dk on 25-01-2019.
 */

import * as PIXI from 'pixi.js'
import { PixiBox } from "../ddennis/PixiBox";
import { PixiRoundRect } from "../ddennis/PixiRoundRect";
import { Graph } from "./Graph";
import { GraphBackground } from "./GraphBackground";
import { GraphBtnContainer } from "./GraphBtnContainer";
import { BottomBar } from "./BottomBar";
import { InfoBox } from "./InfoBox";
import { Hints } from "./Hints";
import TweenMax from 'gsap';

export class GraphView extends PIXI.Container {

	constructor(_app){
		super();
		this.app = _app;
		this.init();
	}


	init(){

		const aa = [
			{name:"INTEREST", value:110, displayValue:100, color:0xb57a00,
				body:"The fifth layer is more of a segmentation than a screening layer and it allows for a category specialization. As an interest is an inextricable element of value redemption, the firstmover’s take will often stand out.",
				label:"16% Last Movers "
			},
			{name:"BEHAVIOR", value:50, color:0xd59000,
				body:"Guided by the change in needs, behavior is the visible result of the firstmover’s request and demand after new products and services in the mass market.",
				label:"34% Late Mainstream"
			},
			{name:"VALUES", value:40, color:0xe59b00,
				body:"The third screening layer represents the values and the everchanging interpretation of values that create the human needs of tomorrow.",
				label:"34% Early Mainstream"
			},

			{name:"SOCIALIZATION", value:28, color:0xf5a602,
				body:"The second screening layer of a firstmover is the degree of socialization – the social network and the influencer’s reach and “see it here first”-footprint in the social sphere.",
				label:"13,5% Next Movers"
			},
			{name:"PERSONALITY", value:12, color:0xffaf08,
				body:"At the core a firstmover’s personality is characterized by the will to – innovate, accept risk, change and act.",
				label:"2,5% First Movers"
			}
		];

		this.barsDataArr = aa.reverse()


		this.graph        = new Graph(this.barsDataArr);
		this.graphOrgSize = {w:1104, h:458}
		this.graph.x      = this.app.renderer.width * .5 - this.graph.width * .5;
		//this.graph.y = this.app.renderer.height -this.graph.height
		this.addChild(this.graph);

		const backPadding = 200;
		this.background   = new GraphBackground(this.app.renderer.width, this.app.renderer.height);
		this.addChildAt(this.background, 0);

		this.infoBox = new InfoBox(this.barsDataArr);
		this.infoBox.hide(0);

		const bars = this.graph.getBars();

		this.bottomBarHeight = 30;
		this.bottomBar   = new BottomBar(this.graph.width, this.bottomBarHeight, this.barsDataArr);
		this.bottomBar.drawLabels();

		//this.bottomBar.y = this.graph.height + this.graph.y;
		this.addChild(this.bottomBar);

		this.btnContainer   = new GraphBtnContainer(this.barsDataArr, this.app , this.infoBox, bars);
		this.btnContainer.on("SELECT", this.btnSelect);
		this.addChild(this.btnContainer);

		this.hints = new Hints(this.barsDataArr);
		this.hints.hide();
		this.addChild(this.hints);

		this.addChild(this.infoBox);



		this.resize()

	}


	btnSelect = (e) => {

		const bar = this.graph.getBarByIndex(e.index)

		//TweenMax.to(bar,.5, {alpha:0, repeat:-1, yoyo:true, ease:"Expo.easeOut"});
		//TweenMax.to(bar,.5, {alpha:0, repeat:-1, yoyo:true, ease:"Expo.easeOut"});
		//console.log (" GraphView.js > what = " ,e);

	}



	resize(){

		const appHeight = this.app.renderer.height;
		const appWidth  = this.app.renderer.width;//- 50;

		const threshold = 991;
		const windowWidth = window.innerWidth

		const graphHeightScale = appHeight / (this.graphOrgSize.h + this.bottomBarHeight + 50);
		const graphWidthScale  = appWidth / (this.graphOrgSize.w + 150);

		const graphScaleFluid = graphHeightScale > graphWidthScale ? graphWidthScale : graphHeightScale;
		const graphScale = Math.min(1, graphScaleFluid);


		if (windowWidth > threshold){
			console.log(" GraphView.js > DESKTOP = ");

			this.graph.scale.y = graphScale;
			this.graph.scale.x = graphScale;

			this.graph.x = (appWidth * .5) - Math.round(this.graph.width) * .5;
			this.graph.y = appHeight - (this.graph.height + this.bottomBarHeight);

			this.btnContainer.visible = true;
			this.btnContainer.resize(appWidth, appHeight,graphScale )

			this.btnContainer.x = this.graph.x//(this.graph.x + this.graph.width * .5) - (this.btnContainer.width * .5);
			this.btnContainer.y = this.graph.y //Math.round((appHeight - this.bottomBar.height) - this.btnContainer.height) - 20 //+ this.bottomBar.height;

			this.bottomBar.y = appHeight - this.bottomBarHeight;
			this.bottomBar.resize(appWidth, this.graph.x, graphScale )
			this.bottomBar.visible = true;

			this.background.resize(appWidth, appHeight)
			this.background.y = this.app.renderer.height - (this.background.height);

			this.hints.hide()
			this.hints.visible = false;

		}

		if (windowWidth < threshold){
			console.log(" GraphView.js > MOBIL = ");

			//let graphWidthScale = (this.app.renderer.width) / (this.graphOrgSize.w + 60);
			//let graphHeightScale = (this.app.renderer.height ) / this.graphOrgSize.h;
			this.graph.scale.x  = graphScale // Math.min(1, graphWidthScale);
			this.graph.scale.y  = graphScale// Math.min(1, graphWidthScale);

			this.btnContainer.visible = false;
			this.bottomBar.visible = false;

			const topPad = 60;
			this.graph.x = (appWidth * .5 - this.graph.width * .5) - 10;
			this.graph.y = appHeight - this.graph.height;

			this.background.resize(appWidth, appHeight);
			this.background.y = 0

			this.hints.resize(appWidth, appHeight, graphScale);
			this.hints.y = (appHeight - this.hints.height * .5) - 50;
			this.hints.x = this.graph.x
			this.hints.show()

		}

		this.background.x = 0;
		this.background.width = appWidth;

	}

}
