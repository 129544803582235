/**
 * Created by @author @ddennis - ddennis.dk aka fantastisk.dk/works aka meresukker.dk on 08-02-2019.
 */

import React, { Component } from 'react';
import { TweenMax } from "gsap/TweenMax";
import ScrollListener from "react-scroll-listen";
import debounce from 'lodash/debounce'
import {ReactComponent as CameraIcon } from '../../assets/svg/camera.svg'
import {ReactComponent as LocationIcon }  from '../../assets/svg/location.svg'
import {ReactComponent as ReachIcon }  from '../../assets/svg/reach.svg'
import {ReactComponent as FollowersIcon } from '../../assets/svg/followers.svg'

import {ReactComponent as EngagementIcon }  from '../../assets/svg/engagement.svg'
import {ReactComponent as InfluencerIcon }  from '../../assets/svg/influencers.svg'


export default class ParallaxItems extends Component {

	myElements = [];

	itemsArr = [
		//{left:"30%", top:"60%", speed:.5, icon:LocationIcon, head:"Location", 	body:"Copenhagen/Denmark"},
		{left:"40%", top:"60%", speed:.5, icon:FollowersIcon, head:"Followers",	body:"160.163"},
		{left:"60%", top:"60%", speed:1.4, icon:EngagementIcon, head:"Engagement", 	body:"16.555"},
		{left:"80%", top:"65%", speed:1.4, icon:CameraIcon, head:"Post", 	body:"65"},
		{left:"80%", top:"60%", speed:1.8, icon:InfluencerIcon, head:"Influencers", body:"15"},
		//{left:"80%", top:"60%", speed:1, icon:ReachIcon, head:"Reach", body:"200.125"}
	];




	onPageScroll = (value) => {

		if (this.props.yOffset === -1){
			return
		}

		const top             = this.props.yOffset;
		const containerHeight = this.props.containerHeight;

		const minScroll = (top - window.innerHeight);
		const maxScroll = (window.document.body.scrollHeight - containerHeight );

		const k = Math.round(this.convertRange(value, minScroll ,maxScroll, 150, 0));

		this.myElements.map((item, index) => {
			const el = item.el;
			TweenMax.to(el, item.speed, {y:k, ease:"Back.easeOut"});
		})


	};

	// Performance debouncer
	//---------------------------------------------------------------------------------------
	myDebounceScroll = debounce(this.onPageScroll);



	convertRange = (num, in_min, in_max, out_min, out_max) => {
		return (num - in_min) * (out_max - out_min) / (in_max - in_min) + out_min;
	};


	componentWillUpdate(nextProps, nextState, nextContext){
		console.log (" ParallaxRowItems.js > nextProps = " , nextProps);
	}

	render(){

		const items = this.itemsArr.map((item, index) => {

			const Icon = item.icon

			return (

			   <div key={index}
					ref={li => this.myElements[index] = {el:li, speed:item.speed}}
					//className="col-lg-2 col-md-4 col-6 icon-box"
					className=" pl-1 pr-1 mt-0 mt-md-3 icon-box"

			   >

				   <div className="h-100 w-100  mt-3 mb-3 mt-lg-1 mb-lg-1 d-flex flex-column justify-content-center " style={{
					   background:"white",
					   boxShadow:"0px 7px 50px 2px rgba(0,0,0,.1)"
				   }}>

					   {/*<div className="align-self-center mh-100 mw-100" >
						   <Icon className="mySvgIcon"></Icon>
					   </div>*/}

					   <div className="text-center pt-1 box-info">
						   <p className="">
							   {item.head.toUpperCase() }
						   </p>
						   <h6 >{item.body}</h6>
					   </div>
				   </div>
			   </div>

			)

		})

		return (

		   <div className=" " style={{position:"relative", zIndex:100}}>

			   {/* for performance passing the activated can removes scrollListener */}
			   {this.props.activated ? <ScrollListener onScroll={this.onPageScroll}/> : null}


			   <div className="d-flex flex-row flex-md-column mt-5" >
				   {items}
			   </div>
		   </div>
		)
	}

}
