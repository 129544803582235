/**
 * Created by @author @ddennis - ddennis.dk aka fantastisk.dk/works aka meresukker.dk on 06-02-2019.
 */
import * as PIXI from 'pixi.js'
import { PixiBox } from "../ddennis/PixiBox";
import { getTxt } from "../Txt";
import TweenMax from 'gsap'
export class InfoBox extends PIXI.Container {


		constructor(_data){

			super();

			this.data = _data
			this.background = new PixiBox(300, 200, 0xFFFFFF)
			this.addChild(this.background)

			this.pad = 40;
			this.txt = getTxt("", 18, 0x1E2AF0, false, null,true, this.background.width - this.pad )
			this.txt.x = this.pad * .5;
			this.txt.y = this.pad * .5;
			this.addChild(this.txt);

		}

		show(index){

			const t =  this.data[index].body
			//console.log (" InfoBox.js > t = " , t);
			this.txt.text = t

			this.txt.alpha = 0
			this.txt.y = 20
			TweenMax.to(this,.3, {alpha:1, ease:"Expo.easeOut"});
			TweenMax.to(this.background,.3, {height:this.txt.height + this.pad, ease:"Expo.easeOut"});
			TweenMax.to(this.txt,.4, {alpha:1,y:this.pad*.5, delay:.1, ease:"Expo.easeOut"});
		}

		hide(speed = .5){
			TweenMax.to(this,speed, {alpha:0,  ease:"Expo.easeOut"});
			TweenMax.to(this.background,speed, { height:20, ease:"Expo.easeOut"});
			TweenMax.to(this.txt,speed - .1, {alpha:0,  ease:"Expo.easeOut"});

		}



		respos(point){
			this.x = point.x;
			this.y = point.y;

		}

}
