/**
 * Created by @author @ddennis - ddennis.dk aka fantastisk.dk/works aka meresukker.dk on 04-02-2019.
 */
import React, { Component } from 'react';
import './run-campaigns.scss'
import CampaignStep from "./CampaignStep";

import stepOne from '../../assets/brands/step-create.png'
import stepTwo from '../../assets/brands/step-select.png'
import stepThree from '../../assets/brands/step-approve.png'
import stepFour from '../../assets/brands/step-report.png'
import { Button } from "reactstrap";
import Reveal from "../animation/Reveal";
import SimpleReveal from "../animation/SimpleReveal";

export default class RunCampaigns extends Component {

	render(){

		return (


					  <div className="generic-page run-campaigns" style={{height:"auto"}}>
						  <SimpleReveal debug >

						  <div className="offwhite ">

							  <div className="row">

								  <div className="col-12  mt-3 mb-4">
									  <div className="text-center page-headline">
										  <h2>Run Campaigns. <span className="d-inline-block">Get Results</span> </h2>
										  <p className="text-center">
											  <i>
												  The FineWires platform have automated the campaign process from the initial brief
												  to end reporting of results.
											  </i>
										  </p>
									  </div>
								  </div>
							  </div>

							  <div className="steps">

								  <CampaignStep
									 head="Create Campaign"
									 img={stepOne}
									 body="With your access to the platform you setup a campaign brief describing the relevant influencer segment,
									  campaign objective, period, influencer tasks, brand values & products to be promoted etc.
									   The brief is then passed to all relevant influencers who will sign on if they are interested in being part of the campaign."
									 index={1}
								  />

								  <CampaignStep
									 head="Select Influencers"
									 img={stepTwo}
									 body="All influencer who are interested and have accepted the requirements of the campaign will appear in the FineWires selection
									  tool from where you will pick & mix the influencers that you want for your campaign based on
									   their Firstmover rating, values, reach, demographics and community."
									 index={2}
								  />
								  <CampaignStep
									 head="Approve Content"
									 img={stepThree }
									 body="Once you have selected the influencers for your campaign they will start producing the campaign content. Some brands will give the influencers full creative freedom to produce
									  and post the content they create – some will like to approve content be published. Both ways works on the platform."
									 index={3}
								  />
								  <CampaignStep
									 head="Get detailed report"
									 img={stepFour}
									 body="During the campaign you get updates on the progress and on the content posted.
									 After the campaign a detailed report on performance of the content from each influencer
									  and for the total impact of the campaign. Further all influencers are scored based on the campaign results in order to optimize the performance of your future campaigns."
									 index={4}
								  />
							  </div>

							  <div className="row" style={{}}>
								  {/*<div className="col text-center mt-4 mb-4" style={{}}>
									  <Button color="primary">Get Started Today</Button>
								  </div>*/}
							  </div>
						  </div>

						  </SimpleReveal>
					  </div>



		)
	}
}
