/**
 * Created by @author @ddennis - ddennis.dk aka fantastisk.dk/works aka meresukker.dk on 14-11-2018.
 */


import React, { PureComponent } from 'react';
import menuData from "../../data/menu";
import { Link } from "react-router-dom";
import './top-navigation.scss'

export default class TopNavigation extends PureComponent {


	render(){

		const color = this.props.color || "";

		const menu = menuData.map((item, index ) => {
			return (
				<div key={item.title} className="nav-item ml-2 mr-2" >
					<Link style={{color:color}} to={item.to}><b>{item.title}</b></Link>
				</div>
			)
		})

		return (
			<div className="top-navigation w-100 d-none d-md-flex justify-content-center" >
				<div className="w-30  d-flex flex-row justify-content-around " >
					{menu}
				</div>
			</div>

		)
	}

}
