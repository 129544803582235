/**
 * Created by @author @ddennis - ddennis.dk aka fantastisk.dk/works aka meresukker.dk on 29-01-2019.
 */
import * as PIXI from 'pixi.js'
import { PixiBox } from "../ddennis/PixiBox";

export class GraphBackground extends PIXI.Container {


		constructor(w, h){
			super();
			this.fakeArr = [1, 2, 3, 4, 5, 6 , 7 ];
			this.arr = []
			this.draw(w, h);
		}


		resize(appWidth, appHeight){

			let ypos = 0;
			const pad = Math.round(appHeight / this.fakeArr.length )

			this.arr.forEach((item, index) => {
				item.y = ypos;
				ypos = ypos + pad;

			})

		}


		draw(w, h){

			let ypos = 0;
			const pad = Math.round(h / this.fakeArr.length )

			this.fakeArr.forEach(() => {
				const line = new PixiBox(w, 1, 0xFFFFFF);
				line.alpha = .6;
				line.y = ypos;
				ypos = ypos + pad;

				this.arr.push(line);
				this.addChild(line)
			})

		}

}
