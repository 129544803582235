/**
 * Created by @author @ddennis - ddennis.dk aka fantastisk.dk/works aka meresukker.dk on 04-02-2019.
 */
import React, { Component } from 'react';

export default class CampaignStep extends Component {

	render(){

		const {head, body, index, img} = this.props;

		const respondsive = " col-md-6  col-lg-5 ";

		const claOne      = respondsive + " step txt-con order-1 " + (index % 2 ? " order-md-0" : "order-md-1");
		const claTwo      = respondsive + " step-item d-md-block step justify-content-center col-sm-5 img-con order-0" + (index % 2 ? " order-md-1" : "order-md-0");

		//const claOne      = respondsive + " step txt-con ";
		//const claTwo      = respondsive + " step-item d-md-block step justify-content-center col-sm-5 img-con "



		return (

		   <div className="row justify-content-center mt-3 " style={{position:"relative"}}>

			   <div className={claOne}>

				   <div className="step-item ">
					   <p className="step-label"><b>STEP {index}</b></p>
					   <h4><b>{head}</b></h4>
					   <p>
						   {body}
					   </p>
				   </div>
			   </div>

			   <div className={claTwo} style={{}}>
				   <div className="d-flex align-items-center ">
					   <img className="mw-100  " src={img} alt=""/>
				   </div>
			   </div>
		   </div>

		)
	}

}

/*
   <div className="col-12 d-flex " style={{position:"absolute", top:0, left:0, height:"100%", width:"100%", background:"green"}}>

	   <div className="d-flex  justify-content-center" style={{position:"absolute", width:"90%", top:6, left:0}}>
		   <div className="d-flex  " style={{position:"absolute",  background:"brown", height:5, width:"90%" }}></div>
	   </div>

	   <div className="d-flex  justify-content-end" style={{position:"absolute", height:"100%",  top:0, left:"90%"}}>
		   <div className="d-flex  " style={{position:"absolute", background:"brown", height:"100%", width:5  }}></div>
	   </div>
</div>

*/


