/**
 * Created by @author @ddennis - ddennis.dk aka fantastisk.dk/works aka meresukker.dk on 08-01-2019.
 */
import * as PIXI from 'pixi.js'
import { PixiBox } from "./ddennis/PixiBox";
import { FloatWrapper } from "./FloatWrapper";
import { GraphView } from "./graph/GraphView";


export class MainView extends PIXI.Container {

	constructor(_app){
		super();
		this.app    = _app;
		this.inited = false;

		this.helperBg = new PixiBox(this.app.renderer.width, this.app.renderer.height)
		//this.addChild(this.helperBg);

		this.currentType = null;
		this.contentLoaded = false
	}

	init(dimensions){

		const maxWidth = 1200;
		this.xOffset   = dimensions.width - 700;
		this._w        = dimensions.width > maxWidth ? maxWidth : dimensions.width;
		this._h        = dimensions.height;

	}


	switchView(type = "float"){

		if(!this.contentLoaded ){
			this.currentType = type;
			this.setupLoader();
			return
		}

		if(type === "float" ){
			//this.showFloat()
		}else{
			this.showGraph()
		}

	}


	showGraph(){

		if(this.getChildByName("float") ){
			this.removeChild(this.floatWrapper)
		}

		this.initGraph();
	}


	showFloat(){
		if(this.getChildByName("float") ){
			this.removeChild(this.floatWrapper)
		}

		this.removeChild(this.graphView)
		this.addChild(this.floatWrapper)
	}



	setupLoader(){

		this.loader = new PIXI.loaders.Loader();
		this.loader.add("shadow", "./assets/shadow.png");
		this.loader.add("graphmask", "./assets/canvas/graphmask.png");
		this.loader.add("spin", "./assets/spin.png");

		this.loader.on('complete', (e) => {
			console.log(" MainView.js > on Complete = ");
			//this.initFloating()
			this.contentLoaded = true;
			this.switchView(this.currentType)
		});

		this.loader.load();
	}




	initFloating(){

		//const parent = this.app.view.parentNode;
		this.floatWrapper             = new FloatWrapper(this._w - 20, this._h);
		this.floatWrapper.name = "float";
		this.floatWrapper.x           = 20//this.xOffset + 20
		this.floatWrapper.interactive = true;

		this.currentView = this.floatWrapper;
		this.addChild(this.floatWrapper)

	}


	initGraph(){

		this.graphView = this.graphView || new GraphView(this.app);
		this.graphView.name = "graph";
		this.currentView = this.graphView;
		this.addChild(this.graphView);
	}






	resize(){


		const parent = this.app.view.parentNode;

		// Resize the renderer
		//this.app.renderer.resize(parent.clientWidth, parent.clientHeight);

		if (this.floatWrapper){
			this.floatWrapper.resize(parent.clientWidth, parent.clientHeight)
		}

		if (this.graphView){
			this.graphView.resize()
		}

		this.helperBg.redraw(this.app.renderer.width, this.app.renderer.height)

		// You can use the 'screen' property as the renderer visible
		// area, this is more useful than view.width/height because
		// it handles resolution
		//rect.position.set(app.screen.width, app.screen.height);

	}


	restart(){
		if(this.floatWrapper ){
			this.floatWrapper.fadeIn()
		}
	}




	stop(){

	};

}
