/**
 * Created by @author @ddennis - ddennis.dk aka fantastisk.dk/works aka meresukker.dk on 29-01-2019.
 */
import * as PIXI from 'pixi.js'
import { PixiRoundRect } from "../ddennis/PixiRoundRect";
import TweenMax from "gsap";
import { PixiBox } from "../ddennis/PixiBox";

export class Graph extends PIXI.Container {

	constructor(_barDataArr){

		super();

		this.barDataArr = _barDataArr

		this.bars      = []
		this.barsPoints      = []
		this.graphMask = new PIXI.Sprite.fromImage('graphmask');

		this.barContainer = new PIXI.Container();
		this.addChild(this.barContainer);

		this.setupBars(_barDataArr, this.graphMask.width, this.graphMask.height, this.barContainer);
		this.barContainer.mask = this.graphMask;
		this.addChild(this.graphMask)

	}

	setupBars(barDataArr, fullWidth, height, container){


		const a = barDataArr.concat([]).reverse()
		a.forEach((item, index) => {

			const w   = Math.round(fullWidth / 100) * item.value;
			const rad = index == 1 ? 50 : 200;

			const color = index === 0 ? 0x000001 : 0xFDDB4C // item.color
			const bar = new PixiBox(w, height + 150, color, 1, rad);
			bar.x     = -200;
			bar.alpha = 0;

			if(index !== 0 ){
				TweenMax.to(bar, 1, {x:0, alpha:1, delay:(index / 10) +1, ease:"Expo.easeOut"});
			}else{

				// this is the darker background
				TweenMax.to(bar, 1, {x:0, alpha:.3, delay:(index / 10) , ease:"Expo.easeOut"});
			}


			this.bars.push(bar)
			container.addChild(bar);

		});

	}


	getBarByIndex(index){
		return this.bars[index]
	}


	getBars(){
		return this.bars
	}

	/*get width(){
		return 1104
	}
	get height(){

		return 485
	}
*/

}
