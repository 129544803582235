/**
 * Created by @author @ddennis - ddennis.dk aka fantastisk.dk/works aka meresukker.dk on 08-01-2019.
 */

import React, { PureComponent } from 'react';
import Brands from "./Brands";
import InterestGraph from "./InterestGraph";
import InfoCard from "./InfoCard";
import RunCampaigns from "./RunCampaigns";
import MariaBlackCase from "./MariaBlackCase";
import Footer from "../footer/Footer";
import TheColab from "./TheColab";

export default class FrontWrapper extends PureComponent {

	render(){

		window.scrollTo(0, 0);

		return (
		   <div className="container-fluid">
			   <Brands/>
				<TheColab></TheColab>
			   <InterestGraph pixiApp={this.props.pixiApp} mainView={this.props.mainView}/>
			   <InfoCard></InfoCard>
			   <RunCampaigns></RunCampaigns>
			   <MariaBlackCase></MariaBlackCase>
			   <Footer/>
		   </div>

		)
	}

}
