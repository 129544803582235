/**
 * Created by @author @ddennis - ddennis.dk aka fantastisk.dk/works aka meresukker.dk on 08-01-2019.
 */
import * as PIXI from 'pixi.js'
import TweenLite from 'gsap/TweenLite'
import { PixiBox } from "./ddennis/PixiBox";
import { Card } from "./Card";

export class FloatWrapper extends PIXI.Container {

	constructor(_w, _h){
		super();

		this.__next = this.next.bind(this)

		this._w = _w;
		this._h = _h;

		this.grid = [
			[0, 0, 0, 0, 0, 6, 0, 0],
			[0, 0, 0, 7, 0, 0, 0, 0],
			[0, 0, 0, 8, 0, 0, 5, 0],
			[0, 3, 0, 0, 0, 0, 0, 0],
			[0, 0, 0, 4, 0, 2, 0, 0],
			[1, 0, 0, 0, 0, 0, 0, 0],
		];

		this.columnWidth  = Math.round(this._w / 8);
		this.columnHeight = Math.round(this._h / 6);

		const wideSmall = 2; //170
		const wideLarge = 3; //270
		const highSmall = 1;
		const highLarge = 1.4;
		const highXL    = 2;
		const boxHigh   = 170;

		this.pageOne = [
			{dummy:"index zero is never userd"},
			{id:1, w:2, h:highSmall, brand:"MARIA BLACK", name:"mandesager", platform:"in", yoff:.2, img:"maria_1.jpg"},
			{
				id:2,
				w:3,
				h:highLarge,
				brand:"MARIA BLACK",
				name:"mandesager",
				platform:"in",
				yoff:0,
				img:"profile_2.jpg"
			},
			{id:3, w:2, h:highLarge, brand:"MARIA BLACK", name:"mandesager", platform:"in", yoff:0, img:"maria_3.jpg"},
			{
				id:4,
				w:2,
				h:highLarge,
				brand:"MARIA BLACK",
				name:"mandesager",
				platform:"in",
				yoff:.2,
				img:"profile_1.jpg"
			},
			{
				id:5,
				w:2,
				h:highLarge,
				brand:"MARIA BLACK",
				name:"mandesager",
				platform:"in",
				yoff:0,
				img:"profile_5.jpg"
			},
			{
				id:6,
				w:3,
				h:highLarge,
				brand:"MARIA BLACK",
				name:"mandesager",
				platform:"in",
				yoff:.3,
				img:"profile_1.jpg"
			},
			{
				id:7,
				w:2,
				h:highSmall,
				brand:"MARIA BLACK",
				name:"mandesager",
				platform:"in",
				yoff:0,
				img:"profile_1.jpg"
			},
			{
				id:8,
				w:3,
				h:highSmall,
				type:"box",
				txt:"Finewires is great it lets me get paid being who i am. Its Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec tincidunt nisi in ante.",
				name:"Camilla Jensen",
				yoff:-.2,
				img:"jennaProfile.jpg"
			}

		];

		this.pageTwo = [
			{dummy:"index zero is never userd"},
			{id:1, w:2, h:highSmall, brand:"MARIA BLACK", name:"mandesager", platform:"in", yoff:.2, img:"maria_1.jpg"},
			{
				id:2,
				w:3,
				h:highLarge,
				brand:"MARIA BLACK",
				name:"mandesager",
				platform:"in",
				yoff:0,
				img:"profile_2.jpg"
			},
			{id:3, w:2, h:highLarge, brand:"MARIA BLACK", name:"mandesager", platform:"in", yoff:0, img:"maria_3.jpg"},
			{
				id:4,
				w:2,
				h:highLarge,
				brand:"MARIA BLACK",
				name:"mandesager",
				platform:"in",
				yoff:.2,
				img:"profile_1.jpg"
			},
			{
				id:5,
				w:2,
				h:highLarge,
				brand:"MARIA BLACK",
				name:"mandesager",
				platform:"in",
				yoff:0,
				img:"profile_5.jpg"
			},
			{
				id:6,
				w:3,
				h:highLarge,
				brand:"MARIA BLACK",
				name:"mandesager",
				platform:"in",
				yoff:.3,
				img:"profile_1.jpg"
			},
			{
				id:7,
				w:2,
				h:highSmall,
				brand:"MARIA BLACK",
				name:"mandesager",
				platform:"in",
				yoff:0,
				img:"profile_1.jpg"
			},
			{
				id:8,
				w:3,
				h:highSmall,
				type:"box",
				txt:"Finewires is great it lets me get paid being who i am. Its Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec tincidunt nisi in ante.",
				name:"Camilla Jensen",
				yoff:-.2,
				img:"jennaProfile.jpg"
			}
		];

		this.pageArr      = [this.pageOne, this.pageTwo];
		this.currentIndex = 0;

		this.btn             = new PixiBox(50, 50, 0xEECCFF);
		this.btn.interactive = true;
		this.btn.y           = 50;

		this.btn.on('pointerdown', () => {
			this.fadeOut()
		});

		this.addChild(this.btn);

		this.itemsArr = []
		this.gotoPage()

	}

	gotoPage(value = 0){

		console.log(" FloatWrapper.js > this.pageArr.length = ", value, this.pageArr.length);
		const index       = value === this.pageArr.length ? 0 : value;
		this.currentIndex = index;
		console.log(" FloatWrapper.js > this.currentIndex  = ", this.currentIndex);
		this.setup(this.currentIndex);
	}

	next(){

		console.log(" FloatWrapper.js > next = ");
		this.gotoPage(this.currentIndex + 1)

	}

	done(maxCount, end){

		return () => {
			--maxCount;
			if (maxCount === 0){
				end()
			}
		}
	}

	end(){
		console.log(" FloatWrapper.js > END END = ");
	}

	fadeOut(){

		console.log(" FloatWrapper.js > this.itemsArr.length = ", this.itemsArr.length);
		const myDone = this.done(this.itemsArr.length - 1, this.__next)

		this.itemsArr.forEach((item, i) => {
			TweenLite.to(item, .5, {y:item.y - 200, delay:i / 22, ease:"Expo.easeOut"});
			TweenLite.to(item, .3, {alpha:0, delay:(i / 22) + .05, ease:"Expo.easeOut", onComplete:myDone});
		})

	}

	fadeIn(){
		this.itemsArr.forEach((item, i) => {
			item.alpha = 0;
			item.y = item.toY + 200
			TweenLite.to(item, .5, {y:item.toY, alpha:1, delay:i / 10, ease:"Expo.easeOut"});
		})
	}





	clean(done){
		var len = this.itemsArr.length;
		for (var i = 0; i < len; i++) {
			const card = this.itemsArr.pop()
			card.cleanUp()
			this.removeChild(card)
			card = null;
		}
	}

	setup(pageIindex){

		this.clean();

		const page = this.pageArr[pageIindex]

		this.grid.forEach((rowArr, rowIndex) => {
			const row = rowArr;

			row.forEach((id, columnIndex) => {
				if (!id){
					return
				}
				const idIndex = id;
				const item    = page[idIndex];

				const w    = this.columnWidth * item.w;
				const h    = w * item.h //this.columnHeight * item.h;
				const card = new Card(item, w, h, 10);

				const yoff     = item.yoff;
				//const xoff = item.xoff
				const alignPad = item.align === "R" ? (this.columnWidth - item.w) : 0;

				//card.x = (this.columnWidth * columnIndex) + (this.columnWidth*.5 - item.w*.5) + xoff;
				//card.x = (this.columnWidth * columnIndex) +  xoff;
				card.x = (this.columnWidth * columnIndex) //+ alignPad;

				//const toY = (this.columnHeight * rowIndex + yoff)
				const offSetY = h * item.yoff
				const toY     = (this.columnHeight * rowIndex) - offSetY
				card.y        = toY + 200;
				card.toY      = toY

				this.itemsArr.push(card);
				card.alpha = 0;
				this.addChild(card)

			})

		});

		this.fadeIn()
	}

	resize(_w, _h){
		this._w = _w;
		this._h = _h;
		console.log(" FloatWrapper.js > resize func this._h = ", this._h);

	}

}


