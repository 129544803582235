/**
 * Created by @author @ddennis - ddennis.dk aka fantastisk.dk/works aka meresukker.dk on 21-02-2019.
 */

import React, { Component } from 'react';

import './influencer-testimoniel.scss';
import { Button } from "reactstrap";
import QuoteIcon from '../../assets/svg/quote.svg'

export default class InfluencerTestimoniels extends Component {

	render(){

		return (

		   <div className="generic-page influencer-testimoniel" style={{minHeight:200}}>

			   <div className="offwhite h-100">

				   <div className="row h-100 ">

					   <div className="col-4 w-100 " style={{paddingRight:0}}>

						   <div className="user-image d-flex h-100 w-100" >

							   <div className="d-none p-4 d-lg-flex flex-column justify-content-end">
								   <div className=" p-3  " style={{borderRadius:5, background:"#ffffff"}}>
									   <div className="position-relative ">
										   <p className="myquote mb-0">
											   “I just finished posting the content I created for two Instagram
											   campaigns for a cool upcoming brand. The brand is very focused on
											   sustainability and as it is definitely a value I share it felt very
											   natural for me to support the brand”.
										   </p>
									   </div>
								   </div>
							   </div>
						   </div>
					   </div>


					   {/*-------- MOILE QUOTE -----------*/}
					   <div className="d-sm-block d-lg-none col-8 h-100 w-100 " style={{paddingLeft:0}}>
						   <div className="h-100 d-flex flex-column justify-content-center p-4"
								style={{background:"#e3e3e3"}}>
							   <div className="position-relative ">
								   <p className="myquote ">
									   “I just finished posting the content I created for two Instagram campaigns for a
									   cool upcoming brand. The brand is very focused on sustainability and as it is
									   definitely a value I share it felt very natural for me to support the brand”.
								   </p>
							   </div>
						   </div>
					   </div>


					   <div className="content col-lg-6 col-12 mb-5 mb-lg-0 " >
						   <div className="col-12 h-100 d-flex flex-column justify-content-center">
							   <div className="  page-headline-left ml-0"
									style={{marginLeft:0, marginRight:0, paddingLeft:0}}>
								   <div>
									   <h1>
										   A brand message I can vouch for
									   </h1>
									   <p className="ml-0 mb-4">
										   At FineWires we match brands and influencers based on common values. In order
										   for an influencer recommendation of a brand to resonate among followers the
										   recommendation has to be honest and reflect real passion.
									   </p>

									   <Button className="pr-4" style={{fontSize:14, boxShadow:"-3px 5px 35px 0px rgba(0, 0, 0, .2)"}}
											   color="primary"><i>Sign up today</i></Button>

								   </div>
							   </div>
						   </div>
					   </div>



				   </div>
			   </div>
		   </div>

		)
	}

}
